<template>
  <floating-spinner />
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { API, PathSegment } from '@/client/axios'
import { Type, type TypesCollection } from '@/model/Type'
import { routeNames } from '@/router'
import type Company from '@/model/Company'
import { TenantCompany } from '@/stores/TenantCompany'
import FloatingSpinner from '@/components/generel/FloatingSpinner.vue'
import { ObjectStorage, StorageKey } from '@/service/ObjectStorage'
import type Settings from '@/model/Settings'
import { UseCase } from '@/model/Settings'
import { TenantSettings } from '@/stores/TenantSettings'
import type { CustomField } from '@/model/CustomField'
import { TenantFields } from '@/stores/TenantFields'

const router = useRouter();


onMounted(async () => {
  if (!(await loadTypes())) {
    console.error('could not load types');
    return;
  }

  if (!await loadSettings()) {
    console.error('could not load settings');
    return;
  }

  if (TenantSettings.get.useCase == UseCase.UNDEFINED) {
    console.log('Push to USE_CASE view');
    await router.push({name: routeNames.USE_CASE});
    return;
  }

  await loadCustomFields();

  if (await isCompanyAvailable()) {
    await router.push({name: routeNames.DASHBOARD});
  } else {
    await router.push({name: routeNames.CREATE_COMPANY});
  }
});

async function isCompanyAvailable() {
  const response = await API.getDataObject<Company>(PathSegment.COMPANIES, '', 'Firma');
  if (!response) return false;
  ObjectStorage.set(StorageKey.COMPANY, response);
  TenantCompany.setCompany(response);
  return true;
}

async function loadTypes() {
  const response = await API.getDataObject<TypesCollection>(PathSegment.TYPES, '', 'Typ');
  if (!response) return false;
  Type.initValues(response);
  ObjectStorage.set(StorageKey.TYPES, response);
  return true;
}

async function loadSettings() {
  const response = await API.getDataObject<Settings>(PathSegment.SETTINGS, '', 'Einstellungen');
  if (!response) return false;
  ObjectStorage.set(StorageKey.SETTINGS, response);
  TenantSettings.setSettings(response);
  return true;
}

async function loadCustomFields() {
  const response = await API.getDataObject<CustomField[]>(PathSegment.CUSTOM_FIELDS, '', 'Benutzerdefinierte Felder');
  if (!response) return false;
  ObjectStorage.set(StorageKey.CUSTOM_FIELDS, response);
  TenantFields.fields = response;
  return true;
}

</script>