import { defaultConfig } from '@formkit/vue';
import { generateClasses } from '@formkit/themes'
import formkitTheme from './formkit.theme.reportpro';
import { genesisIcons } from '@formkit/icons'
import { de } from "@formkit/i18n";

export default defaultConfig({
  locales: { de },
  locale: "de",
  config: {
    classes: generateClasses(formkitTheme),
  },
  // icons: {
  //   ...genesisIcons
  // },
  messages: {
    de: {
      validation: {
        required({ name }) {
          return `*Pflichtfeld`;
        }
      },
      ui: {
        incomplete: 'Einige Felder sind nicht oder inkorrekt ausgefüllt.'
      }
    }
  }
}) 
