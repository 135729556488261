import type { BasicType } from '@/model/Type'
import { useToast } from 'vue-toast-notification'
import type Customer from '@/model/Customer'
import type { ReportPosition } from '@/model/ReportPosition'
import type { OfferPosition } from '@/model/OfferPosition'


export class Utils {

  static $toast = useToast();

  static toSelectOptions(types: BasicType[], emptyOptionLabel: string = 'Typ wählen'): {value: string, label: string}[] {
    return [{value: '', label: emptyOptionLabel}, ...types.map(type => ({ value: type.id, label: type.name }))];
  }

  static getError(e: unknown) {
    if (e instanceof Error) {
      return e.name;
    }
    return 'Unknown error';
  }

  static getCustomerTitle(customer: Customer) {
    return customer.companyName
      ? customer.companyName
      : `${customer.firstname ? customer.firstname : customer.title} ${customer.lastname}`;

  }

  static getCustomerOneLineAddress(customer: Customer) {
    return `${customer.address.street}, ${customer.address.zip} ${customer.address.city}`
  }

  static formatCurrency(value: number): string {
    // Create a formatter for the custom format
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true,
    });

    // Format the value and replace commas with the desired separator
    return formatter.format(value).replace(/,/g, "'");
  }

  static getPickupAndDropLocation(value: string) {
    const split = value.split(';;;');
    return {pickup: split[1], drop: split[2]};
  }

  static isSingleReportPosition(position: ReportPosition) {
    return position.entries.length == 1 && (!position.entries[0].description || position.entries[0].description == '');
  }

  static isSingleOfferPosition(position: OfferPosition) {
    return position.entries.length == 1 && (!position.entries[0].description || position.entries[0].description == '');
  }
}