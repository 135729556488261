<template>

  <spinner v-if="reportPositionLoading"></spinner>

  <base-site v-else :title="titleText" :show-button="!!reportPositionId" button-icon="trash" button-look="secondary-light" @button-action="openModal">

    <base-container class="mb-betweenElements">
      <FormKit v-if="chapters.length != 0" type="select" label="Phase" :options="chapterOptions" v-model="chapterId" />
      <div class="flex flex-row items-center">
        <div class="w-full mr-betweenElements">
          <FormKit type="date" name="date" id="date" label="Datum" validation="required" v-model="date"/>
        </div>
        <div class="ml-auto">
          <FormKit type="checkbox" label="Verrechenbar" :value="true" class="mb-auto rounded-lg !mr-0" v-model="chargeable"/>
        </div>
      </div>

      <custom-fields ref="customFields"
                     :entity="type == ReportType.WORK ? CustomFieldEntity.WORK_REPORT_POSITION : CustomFieldEntity.MATERIAL_REPORT_POSITION"
                     :entity-id="existingPosition?.id ?? ''"
                     :custom-field-values="existingPosition?.customFieldValues" />

    </base-container>


    <div class="flex flex-row space-x-0 h-fit">
      <button :class="`${single ? 'border-b-white hover:cursor-default' : 'text-grey-dark hover:text-primary'} !pl-outerFrameBorder transition-colors md:p-4 p-6 flex rounded-r-none rounded-b-none md:rounded-tl-md rounded-none w-full items-center bg-white border-1 border-grey-mid`" @click="setSingle(true)">
        <phosphor-icon icon="minus" look="regular" />
        <span class="h-full text-center ml-3">Einzelposition</span>
      </button>
      <button :class="`${single ? 'text-grey-dark hover:text-primary' : 'border-b-white hover:cursor-default'} !pl-outerFrameBorder transition-colors md:p-4 p-6 flex md:rounded-tr-md w-full items-center border-l-0 bg-white border-1 border-grey-mid`" @click="setSingle(false)">
        <phosphor-icon icon="list" look="regular" />
        <span class="h-full text-center ml-3">Mehrfachposition</span>
      </button>
    </div>
    <base-container class="mb-betweenElements !rounded-t-none !border-t-0">
      <div v-if="single" class="flex justify-between mb-betweenElements">
        <base-button look="secondary" type="icon" class="ml-auto h-fit rounded-lg" @click="openCategoryModal">
          <phosphor-icon icon="folder-open" />
          <span class="ml-5">{{ type == ReportType.WORK ? 'Pauschale Arbeit wählen' : 'Material wählen' }}</span>
        </base-button>
      </div>

      <category-card ref="categoryCard"
                     :existing="existingPosition"
                     :type="type"
                     :single="single"
                     @receive-description="receiveDescriptionFromCategoryCard">
      </category-card>

      <base-heading v-if="!single" type="h2" class=" pb-4 mt-5 !mb-0">Unterpositionen</base-heading>

      <entries-card ref="entriesCard"
                    :existing-entries="existingPosition?.entries ?? []"
                    :type="type"
                    :single="single"
                    @receive-entries="receiveEntries">
      </entries-card>
    </base-container>


    <div class="flex md:flex-row flex-col">
      <base-button class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{submitText}}</div>
      </base-button>
      <base-button class="md:ml-auto md:mx-0 mx-betweenElements mt-8 md:mt-0 md:w-buttonXLarge" look="secondary-light" @click="onCancel">
        Abbrechen
      </base-button>
    </div>

    <categories-modal ref="categoryModal" :type="type" @receive-modal-category="receiveCategory"></categories-modal>
    <confirmation-modal ref="confirmDeletionModal"
                        title="Rapportposition Löschen"
                        confirm-text="Löschen"
                        @on-confirm="onDelete">
      Soll die Rapportposition wirklich gelöscht werden?
    </confirmation-modal>
  </base-site>

</template>

<script setup lang="ts">

import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { API, PathSegment } from '@/client/axios'
import CategoryCard from '@/components/workReportPosition/CategoryCard.vue'
import router from '@/router'
import BaseButton from '@/components/base/BaseButton.vue'
import { useToast } from 'vue-toast-notification'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import Spinner from '@/components/generel/Spinner.vue'
import BaseSite from '@/components/base/BaseSite.vue'
import { DateConverter } from '@/client/DateConverter'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import type { ReportPosition, ReportPositionRequest } from '@/model/ReportPosition'
import type { ReportPositionEntry } from '@/model/ReportPositionEntry'
import { ReportType } from '@/model/Type'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import type Category from '@/model/Category'
import CategoriesModal from '@/components/workReportPosition/CategoriesModal.vue'
import EntriesCard from '@/components/workReportPosition/EntriesCard.vue'
import { Utils } from '@/client/utils'
import CustomFields from '@/components/generel/CustomFields.vue'
import { CustomFieldEntity, type CustomFieldValue } from '@/model/CustomField'
import BaseContainer from '@/components/base/BaseContainer.vue'
import type { Chapter } from '@/model/Chapter'
import { UrlData } from '@/client/UrlData'

const WORK_REPORT = 'Rapportposition';
const route = useRoute();

const requesting = ref(false);
const reportPositionLoading = ref(true);
const reportPositionId = route.params.id as string;
const subprojectId = route.query.subprojectId as string;
const type = route.query.type as ReportType;
const chapters = (route.query.chapters ? UrlData.toData<Chapter[]>(route.query.chapters as string) : []);
const existingPosition = ref<ReportPosition>();
const date = ref(DateConverter.getCurrentLocalDateISO());
const chapterOptions = ref<{label: string; value: string}[]>([]);
const chargeable = ref(true);

const chapterId = ref<string>('');

const titleText = ref(WORK_REPORT);
const submitText = ref<string>();

const categoryCard = ref(CategoryCard);
const description = ref('');

const entriesCard = ref(EntriesCard);
const entries = ref<ReportPositionEntry[]>([]);

const customFields = ref<InstanceType<typeof CustomFields> | null>(null);
const confirmDeletionModal = ref(ConfirmationModal);

const $toast = useToast();

const single = ref(true);

function receiveDescriptionFromCategoryCard(text: string) {
  description.value = text;
}

function getDescription() {
  if (categoryCard.value) {
    categoryCard.value.emitDescription();
  }
}

function receiveEntries(emittedEntries: ReportPositionEntry[]) {
  entries.value = emittedEntries;
  console.log(emittedEntries);
}

function getEntries() {
  if (entriesCard.value) {
    entriesCard.value.emitForms();
  }
}

function setSingle(value: boolean) {
  single.value = value;
}

async function onSubmit() {
  getDescription();
  getEntries();
  if (entries.value.length <= 0) {
    $toast.error('Kein Arbeiter hinzugefügt');
    return;
  }
  if (!subprojectId) {
    $toast.error('Kein Rapport zugewiesen');
    console.error('subproject not selected!');
    return;
  }
  if (reportPositionId) await onUpdate();
  else await onCreate();
}

async function onCreate() {
  if (requesting.value) return;
  requesting.value = true;
  const response = await API.createDataObject<ReportPosition, ReportPositionRequest>(
    PathSegment.REPORT_POSITIONS,
    {
      invoiceId: subprojectId,
      date: date.value ? DateConverter.convertToUnixTimestamp(date.value) : undefined,
      description: description.value,
      chapterId: chapterId.value == '' ? undefined : chapterId.value,
      chargeable: chargeable.value,
      entries: entries.value,
      reportType: type,
      customFieldValues: getCustomFieldValues()
    }, WORK_REPORT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onUpdate() {
  if (requesting.value) return;
  if (!reportPositionId) {
    $toast.error('Aktuallisieren nicht möglich, keine Raport ID vorhanden');
    console.error('Update failed: ReportPositionId not set');
    return;
  }

  requesting.value = true;
  const response = await API.updateDataObject<ReportPosition, ReportPositionRequest>(
    PathSegment.REPORT_POSITIONS,
    {
      id: reportPositionId,
      invoiceId: subprojectId,
      date: date.value ? DateConverter.convertToUnixTimestamp(date.value) : undefined,
      description: description.value,
      chapterId: chapterId.value == '' ? undefined : chapterId.value,
      chargeable: chargeable.value,
      entries: entries.value,
      reportType: type,
      customFieldValues: getCustomFieldValues()
    }, WORK_REPORT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function loadReportPosition() {
  const response = await API.getDataObject<ReportPosition>(PathSegment.REPORT_POSITIONS, reportPositionId, WORK_REPORT);
  if (!response) return;

  existingPosition.value = response;
  date.value = response.date ? DateConverter.convertToLocalDateISO(response.date) : '';
  chapterId.value = response.chapterId ?? '';
  chargeable.value = response.chargeable;

  single.value = Utils.isSingleReportPosition(response);

  reportPositionLoading.value = false;
}

async function onDelete() {
  const resp = await API.deleteDataObject(PathSegment.REPORT_POSITIONS, reportPositionId, WORK_REPORT);
  if (!resp) return;
  router.go(-1);
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

const categoryModal = ref(CategoriesModal);
const openCategoryModal = () => {
  if (categoryModal.value) {
    categoryModal.value.openModal();
  }
};

function getCustomFieldValues(): CustomFieldValue[] | undefined {
  if (customFields.value) return customFields.value.getValues();
}

function receiveCategory(category: Category) {
  if (categoryCard.value) {
    categoryCard.value.setDescription(category.invoiceInscription);
  }
  if (entriesCard.value) {
    entriesCard.value.receiveCategory(category);
  }
}

onMounted(async () => {
  if (reportPositionId) {
    // edit
    titleText.value = `${type == ReportType.WORK ? 'Arbeits' : 'Material'}rapport bearbeiten`;
    submitText.value = 'Speichern';
    await loadReportPosition();
  } else {
    // create
    titleText.value = `${type == ReportType.WORK ? 'Arbeits' : 'Material'}rapport erstellen`;
    submitText.value = 'Erfassen';
    reportPositionLoading.value = false;
  }
  if (chapters.length == 0) return;
  chapterOptions.value = [{label: 'Keine Phase', value: ''}, ...chapters.map((c => {return {label: `${c.index + 1}. ${c.name}`, value: c.id}}))];
});

</script>

<style scoped>

</style>