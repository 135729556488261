<template>

  <div class="relative max-w-full">
    <div class="fixed inset-0 w-full" @click="onClickOutside"></div>

    <div class="absolute z-10 border bg-white border-grey-mid rounded-sm md:rounded-md overflow-x-auto shadow-lg focus:outline-none w-full" @click.stop>

      <ul>
        <li class="border-b border-grey-mid p-4 hover:text-primary hover:bg-grey-light cursor-pointer transition-all" @click="emits('on-edit')">
          <button class="flex w-full">
            <phosphor-icon icon="pencil" class="mx-auto" />
          </button>
        </li>
        <li class="border-b border-grey-mid p-4 hover:text-primary hover:bg-grey-light cursor-pointer transition-all" @click="emits('on-move-up')">
          <button class="flex w-full">
            <phosphor-icon icon="arrow-up" class="mx-auto" />
          </button>
        </li>
        <li class="border-b border-grey-mid p-4 hover:text-primary hover:bg-grey-light cursor-pointer transition-all" @click="emits('on-move-down')">
          <button class="flex w-full">
            <phosphor-icon icon="arrow-down" class="mx-auto" />
          </button>
        </li>
        <li class="border-grey-mid p-4 hover:text-primary hover:bg-grey-light cursor-pointer transition-all" @click="emits('on-delete')">
          <button class="flex w-full">
            <phosphor-icon icon="trash" class="mx-auto" />
          </button>
        </li>
      </ul>

    </div>
  </div>

</template>

<script setup lang="ts">

import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const emits = defineEmits(['on-move-up', 'on-move-down', 'on-edit', 'on-delete', 'on-click-outside']);

function onClickOutside() {
  emits('on-click-outside');
}

</script>

<style scoped>

</style>