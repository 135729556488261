export enum StorageKey {
  TYPES = 'types',
  COMPANY = 'company',
  SETTINGS = 'settings',
  CUSTOM_FIELDS = 'customFields'
}

export class ObjectStorage {

  static get<T>(key: StorageKey) {
    return JSON.parse(sessionStorage.getItem(key) || 'null') as T | null;
  }

  static set<T>(key: StorageKey, object: T) {
    sessionStorage.setItem(key, JSON.stringify(object));
  }
}