import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import type Customer from '@/model/Customer'
import { TenantCompany } from '@/stores/TenantCompany'

const $toast = useToast();

export async function loadCustomers() {
  try {
    const response = await API.get<Customer[]>(PathSegment.COMPANIES, TenantCompany.getCompany().id, PathSegment.CUSTOMERS);
    if (response.key == ResponseKey.OK) {
      return response.data
    } else {
      $toast.error(`Kunden konnte nicht geladen werden: ${response.message} (${response.key})`);
      console.error(`Customer could not be loaded: ${response.message}`)
    }
  } catch (error) {
    $toast.error(`Kunden konnte nicht geladen werden (${error})`);
    console.error(`Customer could not be loaded: ${error}`);
  }
}