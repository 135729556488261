<template>
  <header class="flex justify-between h-headerHeight sticky top-0 w-full z-20">
    <div v-if="company" class="flex bg-black text-white h-full items-center hover:cursor-pointer" @click="router.push({name: routeNames.DASHBOARD})">
      <p class="px-betweenElements sm:px-outerFrameBorder text-bodyMediumBold uppercase">{{ company?.name }}</p>
    </div>
    <div class="flex justify-end sm:justify-between flex-1 bg-white border-b border-grey-mid h-full items-center px-outerFrameBorder">
      <div class="hidden sm:flex cursor-pointer hover:text-primary transition-colors" @click="onClickUserName">
        <div class="text-bodyMediumNormal">
            <p class="text-bodyMediumBold" @click="onClickUserName">{{ KeycloakService.getUserName() }}</p>
        </div>
      </div>
      <div v-if="company" class="flex items-center">
        <nav class="hidden sm:flex items-center space-x-4 mr-outerFrameBorder h-full">
          <div class="transition-colors text-bodyMediumBold hover:text-primary hover:cursor-pointer"
               @click="onViewDashboard">Dashboard</div>
          <div class="bg-black self-stretch w-[1px]"></div>
          <div class="transition-colors text-bodyMediumBold hover:text-primary hover:cursor-pointer"
               @click="TenantSettings.isProjectOriented() ? onViewCustomers() : onViewProjects()">Kunden</div>
        </nav>
        <TheHamburgerMenuIcon @click="onMenu" />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import TheHamburgerMenuIcon from './TheHamburgerMenuIcon.vue';
import { TenantCompany } from '@/stores/TenantCompany'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router'
import type Company from '@/model/Company'
import { ref } from 'vue'
import { KeycloakService } from '@/service/keycloakService'
import { TenantSettings } from '@/stores/TenantSettings'

const router = useRouter();
const company = ref<Company | undefined>(TenantCompany.getCompany());
TenantCompany.register(emittedCompany => {
  company.value = emittedCompany;
})
const emits = defineEmits(['activate-menu']);

const onMenu = () => {
  emits('activate-menu');
};

async function onViewCustomers() {
  await router.push({name: routeNames.CUSTOMERS});
}

async function onViewProjects() {
  await router.push({name: routeNames.PROJECTS});
}

async function onViewDashboard() {
  await router.push({name: routeNames.DASHBOARD});
}

async function onClickUserName() {
  await router.push({name: routeNames.PROFILE});
}

</script>

<style scoped>
.header-effects {
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.05));
}
</style>
