<template>
  <button :class="`flex items-center btn ${dynamicButtonClasses}`">
    <slot></slot>
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const dynamicButtonClasses = computed(() => {
  let buttonType = '';
  switch(props.type) {
    case 'icon': buttonType = 'p-6 sm:p-5 justify-center'; break;
    case 'mixed': buttonType = 'px-8 py-formY justify-between space-x-betweenElements'; break;
    default: buttonType = 'btn-normal justify-center';
  }

  let buttonLook = '';
  switch(props.look) {
    case 'secondary': buttonLook = 'btn-secondary'; break;
    case 'secondary-light': buttonLook = 'btn-secondary-light'; break;
    default: buttonLook = 'btn-primary';
  }

  return `${buttonType} ${buttonLook}${props.disabled ? ' bg-grey-mid border-grey-mid hover:brightness-100 cursor-default' : ''}`;
});

const props = defineProps({
  type: {
    type: String,
    required: false,
    default: 'normal',
    validator: function (value: string) {
      return ['normal', 'icon', 'mixed'].includes(value);
    }
  },
  look: {
    type: String,
    required: false,
    default: 'primary',
    validator: function (value: string) {
      return ['primary', 'secondary', 'secondary-light'].includes(value);
    }
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  }
})

</script>
