<template>

  <base-container>
    <base-heading>
      <div class="title-icon-with-background">
        <phosphor-icon icon="currency-dollar" />
      </div>
      Preis
    </base-heading>

    <div class="flex flex-row items-center">
      <FormKit type="select" label="Einheit*" :options="Utils.toSelectOptions(Type.getAllUnits(), 'Einheit wählen')" v-model="selectedUnitId"></FormKit>
      <base-button look="secondary" type="icon" class="ml-auto h-fit rounded-lg" @click="openModal">
        <phosphor-icon icon="folder-open" />
        <span class="hidden md:block ml-5">Vorlage auswählen</span>
      </base-button>
    </div>

    <div class="flex flex-row items-center justify-between">
      <div class="mr-betweenElements">
        <FormKit type="number" number inputmode="decimal" :label="`Preis pro ${selectedUnitId ? Type.getUnit(selectedUnitId).abbreviation() : 'Einheit'}*`" :disabled="(total && total != 0) as boolean" v-model="unitPrice"></FormKit>
      </div>
      <div class="ml-auto">
        <FormKit type="number" number inputmode="decimal" label="Totalpreis*" :disabled="(unitPrice && unitPrice != 0) as boolean" v-model="total"></FormKit>
      </div>
    </div>
    <FormKit
      type="text"
      id="calcInput"
      inputmode="decimal"
      :label="`Anzahl ${selectedUnitId ? Type.getUnit(selectedUnitId).abbreviation() : 'Einheit'}*`"
      v-model="amountInput"
      :help="amountHelp"
      @focus="showOperations = true"
      @blur="handleBlur"
    />

    <div v-show="showOperations && !isFirefox" ref="operationBar" class="md:hidden flex justify-around">
      <base-button type="icon" look="secondary" @click="addToInput('+')" class="py-2 px-10 text-titleMedium">+</base-button>
      <base-button type="icon" look="secondary" @click="addToInput('-')" class="py-2 px-10 text-titleMedium">-</base-button>
      <base-button type="icon" look="secondary" @click="addToInput('x')" class="py-2 px-10 text-titleMedium">×</base-button>
      <base-button type="icon" look="secondary" @click="addToInput(':')" class="py-2 px-10 text-titleMedium">÷</base-button>
    </div>

    <!-- Fixed bar at the bottom for Firefox since Firefox is the only mobile Browser that does not overlay the virtual
         keyboard over the web content but actually resizes the page. As long as a no solution like maybe VirtualKeyboard API
         is available for all browsers this is a Firefox feature only-->
    <div v-show="showOperations && isFirefox" class="md:hidden fixed inset-x-0 bottom-0 p-4 bg-white flex justify-around border-t border-grey-mid">
      <base-button type="icon" look="secondary" @click="addToInput('+')" class="py-2 px-10 text-titleMedium">+</base-button>
      <base-button type="icon" look="secondary" @click="addToInput('-')" class="py-2 px-10 text-titleMedium">-</base-button>
      <base-button type="icon" look="secondary" @click="addToInput('x')" class="py-2 px-10 text-titleMedium">×</base-button>
      <base-button type="icon" look="secondary" @click="addToInput(':')" class="py-2 px-10 text-titleMedium">÷</base-button>
    </div>

    <categories-modal ref="modalRef" :type="ReportType.MATERIAL" @receive-modal-category="receiveCategoryFromModal"></categories-modal>

  </base-container>

</template>

<script setup lang="ts">
import BaseContainer from '@/components/base/BaseContainer.vue'
import type Category from '@/model/Category'
import { computed, nextTick, ref, watch, watchEffect } from 'vue'
import { Utils } from '@/client/utils'
import { ReportType, Type } from '@/model/Type'
import { ExpressionCalculator } from '@/service/ExpressionCalculator'
import BaseButton from '@/components/base/BaseButton.vue'
import CategoriesModal from '@/components/workReportPosition/CategoriesModal.vue'
import { useToast } from 'vue-toast-notification'
import type { ReportPosition } from '@/model/ReportPosition'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'


const props = defineProps<{
  existingReportPosition: ReportPosition | undefined
}>();

const emits = defineEmits(['update-price']);

const amountInput = ref('');
const amount = ref(0);
const amountHelp = ref('');
const amountInputValid = ref(false);

const selectedUnitId = ref('');
const unitPrice = ref<number | undefined>();
const total = ref<number | undefined>();
const showOperations = ref(false);
let operationsButtonClicked = false;

const userAgent = navigator.userAgent;
const isFirefox = computed(() => {
  return userAgent.includes('Firefox');
});

watchEffect(() => {
  emits('update-price', {
    amount: amount.value,
    unitId: selectedUnitId.value,
    unitPrice: !unitPrice.value ? undefined : unitPrice.value,
    total: !total.value ? undefined : total.value,
  });
});

watch(() => props.existingReportPosition, newVal => {
  if (!newVal) return;
  amount.value = newVal.entries[0].quantity;
  amountInput.value = `${newVal.entries[0].quantity}`;
  selectedUnitId.value = newVal.entries[0].unitId;
  unitPrice.value = newVal.entries[0].unitPrice;
  total.value = newVal.entries[0].total;
}, {
  immediate: true
});

watch(() => amountInput.value, newVal => {
  const result = ExpressionCalculator.calculate(newVal);
  amountInputValid.value = !!result;
  amount.value = result ?? 0;
  amountHelp.value = result != null ? `= ${amount.value}` : 'Eingabe Fehlerhaft';
}, {
  immediate: true
});

function addToInput(symbol: string) {
  operationsButtonClicked = true;
  amountInput.value += amountInput.value == '' ? symbol : ` ${symbol} `;
  nextTick(() => {
    const inputElement = document.getElementById('calcInput');
    if (inputElement) {
      inputElement.focus();  // Refocus on the input element
    }
  });
}

function handleBlur() {
  setTimeout(() => {
    if (operationsButtonClicked) {
      operationsButtonClicked = false;
      return;
    }
    showOperations.value = false;
  }, 100);  // Delay the hiding to allow button click to execute addToInput
}

const modalRef = ref(CategoriesModal);

const openModal = (categories: Category[]) => {
  if (modalRef.value) {
    modalRef.value.openModal(categories);
  }
};

function receiveCategoryFromModal(category: Category) {
  selectedUnitId.value = category.standardUnitId ?? '';
  unitPrice.value = category.standardPrice ?? 0;
  total.value = undefined
}

</script>

<style scoped>

</style>