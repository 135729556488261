<template>
  <div class="grid">
    <!-- Header -->
    <div class=" grid md:grid-cols-2 grid-cols-1 bg-grey-light">
      <div class="col-span-1 border-b border-grey-dark text-bodyMediumBold">Information</div>
      <div class="col-span-1 border-b border-grey-dark text-bodyMediumBold md:block hidden">Schlüssel</div>
    </div>
    <div v-for="row of tableContent" :key="row.keyName" @click="onClick(row.keyName)"
         class="grid md:grid-cols-2 grid-cols-1 pb-3 pt-3 cursor-pointer hover:ml-2 hover:text-primary transition-all">
      <div class="col-span-1 border-b border-grey-dark">{{row.description}}</div>
      <div class="col-span-1 border-b border-grey-dark md:block hidden">{{row.keyName}}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TenantFields } from '@/stores/TenantFields'
import type { CustomFieldEntity } from '@/model/CustomField'

const props = defineProps<{
  onClick: any
  entity: CustomFieldEntity
}>();

const tableContent = [
  {description: 'Firmenname', keyName: '{{COMPANY_NAME}}'},
  {description: 'Firmennummer (Festnetz)', keyName: '{{COMPANY_LANDLINE_NUMBER}}'},
  {description: 'Firmennummer (Mobil)', keyName: '{{COMPANY_MOBILE_PHONE_NUMBER}}'},
  {description: 'Firmen-Email', keyName: '{{COMPANY_EMAIL}}'},
  {description: 'Firmenname oder Name des Kunden', keyName: '{{CUSTOMER_COMPANY_OR_NAME}}'},
  {description: 'Strassenname der Kundenadresse', keyName: '{{CUSTOMER_ADDRESS_STREET}}'},
  {description: 'Postleitzahl der Kundenadresse', keyName: '{{CUSTOMER_ADDRESS_ZIP}}'},
  {description: 'Stadt der Kundenadresse', keyName: '{{CUSTOMER_ADDRESS_CITY}}'},
  {description: 'Projektname', keyName: '{{PROJECT_NAME}}'},
  {description: 'Strassenname der Projektadresse', keyName: '{{PROJECT_ADDRESS_STREET}}'},
  {description: 'Postleitzahl der Projektadresse', keyName: '{{PROJECT_ADDRESS_ZIP}}'},
  {description: 'Stadt der Projektadresse', keyName: '{{PROJECT_ADDRESS_CITY}}'},
  {description: 'Rapportname', keyName: '{{INVOICE_NAME}}'},
  {description: 'Kostendach der Rechnung', keyName: '{{INVOICE_COST_LIMIT}}'},
  ...TenantFields.forEntity(props.entity).map((field, index) => {
    return {description: field.name, keyName: `{{CF_${field.entity.toUpperCase()}_${index}}}`};
  })
]

</script>

<style scoped>

</style>