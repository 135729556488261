<template>
  <base-container>

    <div class="flex justify-between mb-5">
      <base-heading>
        <div class="title-icon-with-background">
          <phosphor-icon icon="percent" />
        </div>
        Preisänderungs-Typen
      </base-heading>
      <FormKit type="checkbox" label="Verborgene Anzeigen" class="mb-auto rounded-lg" v-model="showHidden" />
    </div>

    <FormKit type="select" label="Preisänderungs-Typ" :options="typeOptions" v-model="selectedTypeId" />

    <div class="flex flex-row mb-betweenElements">
      <radio-select ref="deductionRadioSelect"
                    class="pr-betweenElements border-r border-grey-mid"
                    :options="deductionOrSurchargeOptions"
                    :init-option="isDeduction"
                    @on-select="(value) => isDeduction = value" />
      <radio-select ref="relativeRadioSelect"
                    class="ml-betweenElements"
                    :options="fixOrRelativeOptions"
                    :init-option="isRelative"
                    @on-select="(value) => isRelative = value" />
    </div>

    <FormKit type="text" label="Name*" validation="required" v-model="name" />
    <FormKit type="number" number step="any" label="Standart Betrag" inputmode="decimal" v-model="standardAmount" />

    <div class="flex md:flex-row flex-col">
      <base-button class="md:mr-auto md:mb-0 mb-8 md:w-buttonXLarge" @click="onSubmit">
        <base-icon v-if="!requesting" class="mr-5">save</base-icon>
        <spinner-small v-if="requesting" />
        <div v-else>{{selectedTypeId == '' ? 'Erstellen' : 'Speichern'}}</div>
      </base-button>
      <base-button class="md:ml-auto md:w-buttonXLarge" look="secondary" v-if="selectedTypeId != ''" @click="Type.getDiscountType(selectedTypeId).hidden ? onSetVisibility(true) :  openModal()">
        <base-icon class="mr-5">{{ Type.getDiscountType(selectedTypeId).hidden ? 'visibility' : 'visibility_off' }}</base-icon>
        {{ Type.getDiscountType(selectedTypeId).hidden ? 'Wiederherstellen' : 'Verbergen' }}
      </base-button>
    </div>

    <confirmation-modal ref="confirmDeletionModal"
                        title="Preisänderungs-Typ verbergen"
                        confirm-text="Verbergen"
                        @on-confirm="onSetVisibility(false)">
      Soll der Preisänderungs-Typ wirklich verborgen werden?
    </confirmation-modal>

  </base-container>
</template>

<script setup lang="ts">

import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { nextTick, ref, watch } from 'vue'
import { Utils } from '@/client/utils'
import { Type, type TypesCollection } from '@/model/Type'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import BaseButton from '@/components/base/BaseButton.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import RadioSelect from '@/components/generel/RadioSelect.vue'

const showHidden = ref(false);

const typeOptions = ref(getOptions());
const selectedTypeId = ref('');
const name = ref('');
const fixOrRelativeOptions = [
  {label: 'Fixbetrag', value: false},
  {label: 'Prozentsatz', value: true}];
const deductionOrSurchargeOptions = [
  {label: 'Reduktion', value: true},
  {label: 'Aufschlag', value: false}];
const isRelative = ref(false);
const standardAmount = ref<number | undefined>();
const isDeduction = ref(true);

const requesting = ref(false);
const confirmDeletionModal = ref(ConfirmationModal);
const relativeRadioSelect = ref(RadioSelect);
const deductionRadioSelect = ref(RadioSelect);

const $toast = useToast();

async function onSubmit() {
  if (selectedTypeId.value == '') onCreateDiscountType();
  else onUpdateDiscountType();
}

function getOptions() {
  return showHidden.value
    ? [{label: 'Neuer Preisänderungs-Typ', value: ''}, ...Type.getAllDiscountTypesUnfiltered().map(d => {
      return {label: `${d.name}${d.hidden ? ' (verborgen)' : ''}`, value: d.id}
    })]
    : Utils.toSelectOptions(Type.getAllDiscountTypes(), 'Neuer Preisänderungs-Typ');
}

async function onCreateDiscountType() {
  requesting.value = true;
  const response = await API.post<TypesCollection>(PathSegment.TYPES_DISCOUNT_TYPES, {
    id: '',
    name: name.value,
    standardAmount: standardAmount.value,
    relative: isRelative.value,
    deduction: isDeduction.value,
    hidden: false
  });
  requesting.value = false;
  if (response && response.key == ResponseKey.CREATED && response.data) {
    const ids = new Set(Type.getAllDiscountTypes().map(d => d.id));
    const newDiscountTypes = response.data.discountTypes.filter(d => !d.hidden).find(dt => !ids.has(dt.id));
    const newId = newDiscountTypes ? newDiscountTypes.id : '';

    Type.updateDiscountTypes(response.data.discountTypes);
    typeOptions.value = getOptions();
    $toast.success(`Preisänderungs-Typ erstellt`);
    selectedTypeId.value = newId;
  } else {
    $toast.error("Erstellen des Preisänderungs-Typ fehlgeschlagen");
  }
}

async function onUpdateDiscountType() {
  const selectedDiscountType = Type.getDiscountType(selectedTypeId.value);
  requesting.value = true;
  const response = await API.put<TypesCollection>(PathSegment.TYPES_DISCOUNT_TYPES, {
    id: selectedTypeId.value,
    name: name.value,
    standardAmount: standardAmount.value,
    relative: isRelative.value,
    deduction: isDeduction.value,
    hidden: selectedDiscountType.hidden
  });
  requesting.value = false;
  if (response && response.key == ResponseKey.UPDATED && response.data) {
    Type.updateDiscountTypes(response.data.discountTypes);
    typeOptions.value = getOptions();
    $toast.success(`Preisänderungs-Typ aktuallisiert`);
  } else {
    $toast.error("Aktuallisieren des Preisänderungs-Typ fehlgeschlagen");
  }
}

async function onSetVisibility(visible: boolean) {
  const selectedDiscountType = Type.getDiscountType(selectedTypeId.value);
  requesting.value = true;
  const response = await API.put<TypesCollection>(PathSegment.TYPES_DISCOUNT_TYPES, {
    id: selectedDiscountType.id,
    name: selectedDiscountType.name,
    standardAmount: selectedDiscountType.standardAmount,
    relative: selectedDiscountType.relative,
    deduction: selectedDiscountType.deduction,
    hidden: !visible
  });
  requesting.value = false;
  if (response && response.key == ResponseKey.UPDATED && response.data) {
    selectedTypeId.value = '';
    if (visible) selectedTypeId.value = selectedDiscountType.id;
    Type.updateDiscountTypes(response.data.discountTypes);
    typeOptions.value = getOptions();
    $toast.success(`Preisänderungs-Typ ${visible ? 'wiederhergestellt' : 'verborgen'}`);
  } else {
    $toast.error(`${visible ? 'Wiederherstellen' : 'Verbergen'} des Preisänderungs-Typ fehlgeschlagen`);
  }
}

watch(() => selectedTypeId.value, async newVal => {
  if (!newVal || newVal == '') {
    await nextTick();
    setRelative(false);
    setDeduction(true);
    name.value = '';
    standardAmount.value = undefined;
  } else {
    const discountType = Type.getDiscountType(newVal);
    setDeduction(discountType.deduction);
    setRelative(discountType.relative);
    name.value = discountType.name;
    standardAmount.value = discountType.standardAmount;
  }
}, {
  immediate: true
});

watch(() => showHidden.value, newVal => {
  typeOptions.value = getOptions();
  selectedTypeId.value = '';
}, {
  immediate: true
});

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

function setRelative(value: boolean) {
  isRelative.value = value;
  if (!relativeRadioSelect.value) return;
  relativeRadioSelect.value.setSelected(value);
}

function setDeduction(value: boolean) {
  isDeduction.value = value;
  if (!deductionRadioSelect.value) return;
  deductionRadioSelect.value.setSelected(value);
}

</script>