<template>
  <base-container>
    <base-heading>
      <div class="title-icon-with-background">
        <base-icon>assignment</base-icon>
      </div>
      Beschreibung
    </base-heading>

    <div class="flex flex-row">
      <div class="w-full">
        <FormKit type="textarea" label="Detailbeschreibung" v-model="descriptionText" />
      </div>
      <button class="pl-betweenElements" @click="openTranscriptionModal">
        <phosphor-icon icon="microphone" />
      </button>
    </div>

    <transcription-modal ref="transcriptionModalRef"
                         :existing-text="descriptionText"
                         @on-transcribed="onTranscribed" />
  </base-container>
</template>

<script setup lang="ts">
import { defineExpose, ref, watch } from 'vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { ReportType } from '@/model/Type'
import { useToast } from 'vue-toast-notification'
import TranscriptionModal from '@/components/workReportPosition/TranscriptionModal.vue'
import type { OfferPosition } from '@/model/OfferPosition'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'

const props = defineProps<{
  existing: OfferPosition | undefined
  type: ReportType
  single: boolean
}>();

const descriptionText = ref('');

const emits = defineEmits(['receive-data']);

const $toast = useToast();

watch(() => props.existing, newVal => {
  if (!newVal || newVal.id == undefined) return;
  descriptionText.value = newVal.description;
}, {
  immediate: true
});

watch(() => descriptionText.value, newVal => {
  if (newVal == undefined) return;
  emitDescription()
}, {
  immediate: true
});

// emitting selected categories to parent component
function emitDescription() {
  emits('receive-data', descriptionText.value);
}

const transcriptionModalRef = ref(TranscriptionModal);
const openTranscriptionModal = () => {
  if (transcriptionModalRef.value) {
    transcriptionModalRef.value.openModal();
  }
};

function onTranscribed(text: string) {
  if (!text || text == '') return;
  descriptionText.value = text;
}

function setDescription(text: string) {
  descriptionText.value = text;
}

defineExpose({
  emitDescription,
  setDescription
})

</script>

<style scoped>

</style>