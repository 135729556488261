<template>

  <base-site :title="titleText" :show-button="!!offerId && !deciding" button-icon="trash" button-look="secondary-light" @button-action="openDeleteModal">
    <base-container class="mb-betweenElements">

      <spinner v-if="loading" />

      <div v-else-if="deciding" class="flex flex-col items-center justify-center text-center h-full">
        <p class="text-bodyMediumBold align-middle m-11">Möchten Sie einen Rapport zu der Offerte erstellen?</p>
        <div class="flex flex-row">
          <base-button look="secondary" class="mr-betweenElements md:w-buttonXLarge" @click="router.go(-1)">
            Nein
          </base-button>
          <base-button class="md:w-buttonXLarge" @click="onAddSubproject()">
            Ja
          </base-button>
        </div>
      </div>

      <div v-else>
        <FormKit type="text" name="name" id="name" label="Name der Offerte*" validation="required" v-model="offerName" />

        <custom-fields ref="customFields"
                       :entity="CustomFieldEntity.OFFER"
                       :entity-id="offer?.id ?? ''"
                       :custom-field-values="offer?.customFieldValues" />

        <base-heading v-if="offerId" type="h2" class=" pb-4 mt-5 !mb-0">Status*</base-heading>
        <div v-if="offerId" class="flex flex-wrap justify-between">
          <button :class="`flex-grow border-2 rounded-md px-10 text-bodyMediumBold py-7 sm:py-6 mr-3 last:mr-0 mb-3`" :style="`color: ${offerStatusColor(OfferStatus.OPEN)}; border-color: ${offerStatus == OfferStatus.OPEN ? offerStatusColor(OfferStatus.OPEN) : '#f5f5ff'}`" @click="onChangeStatus(OfferStatus.OPEN)">{{translateOfferStatus(OfferStatus.OPEN)}}</button>
          <button :class="`flex-grow border-2 rounded-md px-10 text-bodyMediumBold py-7 sm:py-6 mr-3 last:mr-0 mb-3`" :style="`color: ${offerStatusColor(OfferStatus.ACCEPTED)}; border-color: ${offerStatus == OfferStatus.ACCEPTED ? offerStatusColor(OfferStatus.ACCEPTED) : '#f5f5ff'}`" @click="onChangeStatus(OfferStatus.ACCEPTED)">{{translateOfferStatus(OfferStatus.ACCEPTED)}}</button>
          <button :class="`flex-grow border-2 rounded-md px-10 text-bodyMediumBold py-7 sm:py-6 mr-3 last:mr-0 mb-3`" :style="`color: ${offerStatusColor(OfferStatus.REJECTED)}; border-color: ${offerStatus == OfferStatus.REJECTED ? offerStatusColor(OfferStatus.REJECTED) : '#f5f5ff'}`" @click="onChangeStatus(OfferStatus.REJECTED)">{{translateOfferStatus(OfferStatus.REJECTED)}}</button>
        </div>

        <dropdown v-if="!TenantSettings.isTransport()" label="Phasen">
          <div v-if="chapters.length == 0" class="text-grey-dark text-bodyMediumBold p-5">Keine Phase vorhanden</div>
          <div v-for="chapter of chapters" :key="chapter.index" class="flex flex-row p-5 border border-grey-mid rounded mt-5 items-center">
            <span>{{ chapter.index + 1 }}. {{ chapter.name }}</span>
            <div class="ml-auto w-15">
              <button class="hover:text-primary transition-colors pl-10" @click="chapterMenuVisible[chapter.index] = true">
                <phosphor-icon icon="dots-three-vertical" />
              </button>
              <chapter-menu v-if="chapterMenuVisible[chapter.index]"
                            @on-click-outside="chapterMenuVisible[chapter.index] = false"
                            @on-edit="onEditChapter(chapter.index)"
                            @on-move-up="onMoveChapterUp(chapter.index)"
                            @on-move-down="onMoveChapterDown(chapter.index)"
                            @on-delete="onDeleteChapter(chapter.index)" />
            </div>
          </div>
          <base-button look="secondary" class="mt-5 w-full rounded-sm p-5" @click="openChapterNameModal">
            <phosphor-icon icon="plus" />
          </base-button>
        </dropdown>

        <confirmation-modal ref="confirmDeletionModal"
                            title="Offerte löschen"
                            confirm-text="Löschen"
                            @on-confirm="onDelete">
          Soll das die Offerte wirklich gelöscht werden?</confirmation-modal>

        <confirmation-modal ref="chapterNameModal"
                            :title="chapterEditIndex == -1 ? 'Phase hinzufügen' : 'Phase bearbeiten'"
                            :confirm-text="chapterEditIndex == -1 ? 'Hinzufügen' : 'Speichern'"
                            @on-confirm="() => chapterEditIndex == -1 ? onAddChapter() : editChapter()"
                            @close-action="chapterName = ''; chapterEditIndex = -1">
        <FormKit type="text" label="Name*" validation="required" v-model="chapterName" />
      </confirmation-modal>

      </div>
    </base-container>

    <div v-if="!loading && !deciding" class="flex md:flex-row flex-col">
      <base-button look="primary" :disabled="!validate()" class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{submitText}}</div>
      </base-button>
      <base-button look="secondary-light" class="md:mx-0 md:mt-0 mt-betweenElements mx-betweenElements md:w-buttonXLarge" @click="onCancel">Abbrechen</base-button>
    </div>
  </base-site>

</template>

<script setup lang="ts">

import { API, PathSegment } from '@/client/axios'
import { OfferStatus, offerStatusColor, translateOfferStatus } from '@/model/Type'
import { useRoute } from 'vue-router'
import router, { routeNames } from '@/router'
import { onMounted, ref } from 'vue'
import BaseSite from '@/components/base/BaseSite.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import type { Offer, OfferRequest } from '@/model/Offer'
import Spinner from '@/components/generel/Spinner.vue'
import { CustomFieldEntity, type CustomFieldValue } from '@/model/CustomField'
import CustomFields from '@/components/generel/CustomFields.vue'
import { FormValidator, Validation } from '@/client/FormValidator'
import { TenantSettings } from '@/stores/TenantSettings'
import type { ChapterRequest } from '@/model/Chapter'
import { useToast } from 'vue-toast-notification'
import ChapterMenu from '@/components/invoice/ChapterMenu.vue'
import Dropdown from '@/components/generel/Dropdown.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { UrlData } from '@/client/UrlData'

const OFFER = 'Offerte';
const route = useRoute();

const loading = ref(true);
const requesting = ref(false);
const deciding = ref(false);
const offerId = route.params.id as string;
const offer = ref<Offer>();
const projectId = route.query.projectId as string;
const titleText = ref('Offerte');
const submitText = ref<string>();

const offerName = ref<string>('');
const offerStatus = ref<OfferStatus>(OfferStatus.OPEN);

const chapterName = ref('');
const chapterEditIndex = ref(-1);
const chapters = ref<ChapterRequest[]>([]);
const chapterNameModal = ref(ConfirmationModal);
const chapterMenuVisible = ref<boolean[]>([]);

const confirmDeletionModal = ref(ConfirmationModal);

const customFields = ref<InstanceType<typeof CustomFields> | null>(null);

const $toast = useToast();

async function onSubmit() {
  if (!validate()) return;
  if (!projectId && !offer.value) {
    console.error('Offer or project id is not set!');
    return;
  }

  if (offerId) await onUpdate();
  else await onCreate();
}

async function onCreate() {
  if (requesting.value) return;
  const offerRequest: OfferRequest = {
    projectId: projectId,
    name: offerName.value,
    status: OfferStatus.OPEN,
    chapters: chapters.value,
    customFieldValues: getCustomFieldValues()
  }
  requesting.value = true;
  const response = await API.createDataObject<Offer, OfferRequest>(PathSegment.OFFERS, offerRequest, OFFER);
  if (response) await router.push({name: routeNames.PROJECT, params: {id: response.projectId}});
  requesting.value = false;
}

async function onUpdate() {
  if (requesting.value) return;
  const offerRequest: OfferRequest = {
    id: offerId,
    projectId: offer.value?.projectId,
    name: offerName.value,
    status: offerStatus.value,
    chapters: chapters.value,
    customFieldValues: getCustomFieldValues()
  }
  requesting.value = true;
  const response = await API.updateDataObject<Offer, OfferRequest>(PathSegment.OFFERS, offerRequest, OFFER);
  if (response)
    if (!response.linked && response.status == OfferStatus.ACCEPTED) deciding.value = true;
    else router.go(-1);
  requesting.value = false;
}

function onAddSubproject() {
  router.push({
    name: routeNames.CREATE_SUBPROJECT, 
    query: {
      projectId: offer.value?.projectId, 
      offerId: offerId, name: offer.value?.name, 
      costLimit: offer.value?.costs,
      chapters: UrlData.toUrlData(offer.value?.chapters)
    }});
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.OFFERS, offerId, OFFER);
  if (success) {
    router.go(-1);
  }
}

function onCancel() {
  router.go(-1);
}

const openDeleteModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

async function loadOffer() {
  loading.value = true;
  const response = await API.getDataObject<Offer>(PathSegment.OFFERS, offerId);
  if (!response) return;
  offer.value = response;
  offerName.value = response.name;
  offerStatus.value = response.status;
  chapters.value = response.chapters;
  chapterMenuVisible.value = response.chapters.map(() => false);
  loading.value = false;
}

function onChangeStatus(status: OfferStatus) {
  offerStatus.value = status;
}

function getCustomFieldValues(): CustomFieldValue[] | undefined {
  if (customFields.value) return customFields.value.getValues();
}

function validate() {
  return FormValidator.validate([
    {value: offerName.value, validation: Validation.REQUIRED},
  ])
}

function onAddChapter() {
  if (!chapterName.value) {
    $toast.error('Kein Phasenname angegeben');
    return;
  }
  if (chapters.value.find(c => c.name === chapterName.value)) {
    $toast.error('Phasenname bereits vergeben');
    return;
  }
  chapters.value = [...chapters.value, {name: chapterName.value, index: chapters.value.length}];
  chapterName.value = '';
  chapterMenuVisible.value = [...chapters.value.map(() => false)];
}

function onEditChapter(index: number) {
  chapterMenuVisible.value[index] = false;
  chapterEditIndex.value = index;
  chapterName.value = chapters.value[index].name;
  openChapterNameModal();
}

function editChapter() {
  if (!chapterName.value) {
    $toast.error('Kein Phasenname angegeben');
    return;
  }
  if (chapters.value.find(c => c.name === chapterName.value)) {
    $toast.error('Phasenname bereits vergeben');
    return;
  }
  chapters.value[chapterEditIndex.value].name = chapterName.value;
  chapterName.value = '';
  chapterEditIndex.value = -1;
}

function onMoveChapterUp(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index <= 0 || index >= chapters.value.length) {
    console.warn("Cannot move the object up further.");
    return;
  }

  const temp = chapters.value[index];
  chapters.value[index] = chapters.value[index - 1];
  chapters.value[index - 1] = temp;

  chapters.value[index].index = index;
  chapters.value[index - 1].index = index - 1;
}

function onMoveChapterDown(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index < 0 || index >= chapters.value.length - 1) {
    console.warn("Cannot move the object down further.");
    return;
  }

  const temp = chapters.value[index];
  chapters.value[index] = chapters.value[index + 1];
  chapters.value[index + 1] = temp;

  chapters.value[index].index = index;
  chapters.value[index + 1].index = index + 1;
}

function onDeleteChapter(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index < 0 || index >= chapters.value.length) {
    console.warn("Invalid index. Cannot remove the chapter.");
    return;
  }

  chapters.value.splice(index, 1);

  for (let i = index; i < chapters.value.length; i++) {
    chapters.value[i].index = i;
  }
}

function openChapterNameModal() {
  if (!chapterNameModal.value) return;
  chapterNameModal.value.openModal();
}

onMounted(async () => {
  if (!offerId && !projectId) {
    console.error('Offer or project id is not set!');
    return;
  }

  if (offerId) {
    titleText.value = 'Offerte brearbeiten';
    submitText.value = 'Offerte speichern';
    await loadOffer();
  } else {
    loading.value = false;
    titleText.value = 'Offerte erstellen';
    submitText.value = 'Offerte erfassen';
  }
});

</script>

<style scoped>

</style>