
export class ExpressionCalculator {

  public static calculate(input: string): number | null {
    // Regular expression to match a valid mathematical expression including , as a decimal separator
    const validExpression = /^-?[0-9+\-*/\sx:,.]+$/;

    if (!validExpression.test(input)) {
      console.log('Not Valid');
      return null;
    }

    // Replace aliases x with * and : with /
    input = input.replace(/,/g, '.')
                 .replace(/x/g, '*')
                 .replace(/:/g, '/');

    try {
      // Parse the expression and evaluate it
      const result =  this.evaluate(this.parseExpression(input));
      if (isNaN(result) || result == Number.POSITIVE_INFINITY || result == Number.NEGATIVE_INFINITY) return null;
      return Math.round(result * 100) / 100;
    } catch (e) {
      return null;
    }
  }

  private static parseExpression(input: string): (number | string)[] {
    const outputQueue: (number | string)[] = [];
    const operatorStack: string[] = [];
    const operators: { [key: string]: { precedence: number, associativity: string } } = {
      '+': { precedence: 2, associativity: 'Left' },
      '-': { precedence: 2, associativity: 'Left' },
      '*': { precedence: 3, associativity: 'Left' },
      '/': { precedence: 3, associativity: 'Left' }
    };

    const tokens = input.match(/-?\d+(\.\d+)?|[+\-*/]/g);
    if (!tokens) {
      throw new Error("Invalid expression");
    }

    tokens.forEach(token => {
      if (!isNaN(parseFloat(token))) {
        outputQueue.push(parseFloat(token));
      } else if (token in operators) {
        const o1 = token;
        while (operatorStack.length > 0) {
          const o2 = operatorStack[operatorStack.length - 1];
          if (o2 in operators && (
            (operators[o1].associativity === 'Left' && operators[o1].precedence <= operators[o2].precedence) ||
            (operators[o1].associativity === 'Right' && operators[o1].precedence < operators[o2].precedence)
          )) {
            outputQueue.push(operatorStack.pop() as string);
          } else {
            break;
          }
        }
        operatorStack.push(o1);
      }
    });

    while (operatorStack.length > 0) {
      outputQueue.push(operatorStack.pop() as string);
    }

    return outputQueue;
  }

  private static evaluate(rpn: (number | string)[]): number {
    const stack: number[] = [];

    rpn.forEach(token => {
      if (typeof token === 'number') {
        stack.push(token);
      } else {
        const b = stack.pop() as number;
        const a = stack.pop() as number;
        switch (token) {
          case '+':
            stack.push(a + b);
            break;
          case '-':
            stack.push(a - b);
            break;
          case '*':
            stack.push(a * b);
            break;
          case '/':
            stack.push(a / b);
            break;
        }
      }
    });

    return stack[0];
  }

}