<template>

  <base-container class="!bg-blue-mid">
    <div class="flex justify-between mb-5">
      <base-heading class="mr-auto">
        <div class="title-icon-with-background">
          <phosphor-icon :icon="type == ReportType.WORK ? 'hammer' : 'package'" />
        </div>
        {{ type == ReportType.WORK ? 'Arbeiten' : 'Material' }}
      </base-heading>
      <base-button v-if="!locked" class="mb-auto rounded-lg bg-blue-mid ml-betweenElements" type="icon" look="secondary" @click="onAddOfferPosition()">
        <phosphor-icon icon="plus" />
      </base-button>
    </div>

    <div class="flex flex-row overflow-hidden">
      <FormKit v-if="offer.chapters.length > 0" type="select" :classes="{outer: 'ml-auto max-w-parent', input: '!bg-blue-mid'}" v-model="chapterId" 
        :options="[{label: 'Alle Phasen', value: ChapterDefault.ALL}, ...offer.chapters.map(c => {return {label: `${c.index + 1}. ${c.name}`, value: c.id}}), {label: 'Keine Phase', value: ChapterDefault.NONE}]" />
    </div>

    <spinner v-if="positionsLoading" />

    <div v-else>
      <div v-if="tableOfferPositions.length == 0">
        <p class="text-bodyMediumBold">Keine Einträge vorhanden</p>
      </div>
      <div v-else>


        <!-- Desktop Table -->
        <div class="hidden md:block">
          <div v-if="tableOfferPositions" :class="`grid ${tableColumns(offer)} auto-rows-[minmax(min-content,max-content)]`">

            <!-- Header -->
            <div class="contents mx-2 my-2 items-center">

              <div v-if="copyingAllowed" :class="`pl-3 pr-0 sm:py-4 border border-r-0 rounded-l-md border-grey-mid bg-grey-mid mb-2 min-w-8 cursor-pointer hover:text-primary transition-colors text-bodySmallNormal ${allChecked ? 'text-primary' : ''}`" @click="checkAll(!allChecked)">
                <phosphor-icon :icon="allChecked ? 'check-square' : 'square'" :look="allChecked ? 'fill' : 'regular'" />
              </div>
              <div :class="`pl-3 pr-0 ${copyingAllowed ? 'py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2' : 'pl-3 pr-0 sm:py-4 border border-r-0 rounded-l-md border-grey-mid bg-grey-mid mb-2 min-w-8'}`"></div>
              <div v-if="offer.chapters.length > 0" class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                P
              </div>
              <div class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                Beschreibung
              </div>
              <div class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                Menge
              </div>
              <div class="pr-8 pl-0 py-7 sm:py-4 border-t border-b border-grey-mid bg-grey-mid mb-2">
                Einheitspreis
              </div>
              <div class="pr-4 pl-0 py-4 text-right border border-l-0 rounded-r-md border-grey-mid bg-grey-mid mb-2">
                Total
              </div>

            </div>

            <!-- Content -->
            <div v-for="(cell, index) in tableOfferPositions" :key="index" class="contents mx-2 my-2 items-center" :class="`${cell.visible || locked ? '' : 'hover:text-primary'} ${locked ? '' : 'row hover:cursor-pointer'}`">

              <div v-if="copyingAllowed" class="text-bodySmallNormal pl-3 pr-0 pt-5 pb-3 border border-r-0 border-grey-mid mt-2 cursor-pointer hover:text-primary transition-colors"
                   :class="`${cell.visible ? 'mb-0 border-primary rounded-tl-md' : 'mb-2 border-grey-mid rounded-l-md'} ${cell.checked ? 'text-primary' : ''}`"
                    @click="checkCell(index)">
                <phosphor-icon :icon="cell.checked ? 'check-square' : 'square'" :look="cell.checked ? 'fill' : 'regular'" />
              </div>

              <div @click="Utils.isSingleOfferPosition(cell.position) ? onEditOfferPosition(cell.position.id) : toggleVisibility(index)"
                   :class="`pl-3 pr-0 pt-5 pb-3 ${copyingAllowed ? 'border-t border-b border-grey-mid mt-2' : 'text-bodyMediumNormal border border-r-0 border-grey-mid mt-2'} ${cell.visible ? 'mb-0 border-primary' + (copyingAllowed ? '' : ' rounded-tl-md') : 'mb-2 border-grey-mid' + (copyingAllowed ? '' : ' rounded-l-md')}`">
                <button class="pt-3 pb-0">
                  <base-icon class="h-5" v-if="!Utils.isSingleOfferPosition(cell.position)">
                    {{cell.visible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                  </base-icon>
                </button>
              </div>

              <div v-if="offer.chapters.length > 0" class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2" :class="`${cell.visible ? 'mb-0 border-primary' : 'mb-2 border-grey-mid'}`"
                   @click="onEditOfferPosition(cell.position.id)">
                   {{ cell.position.chapterId ? (offer.chapters.find(c => c.id == cell.position.chapterId)?.index ?? -1) + 1 : '-'}}
              </div>

              <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2" :class="`${cell.visible ? 'mb-0 border-primary' : 'mb-2 border-grey-mid'}`"
                   @click="onEditOfferPosition(cell.position.id)">
                {{ cell.position.description }}
              </div>

              <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2" :class="`${cell.visible ? 'mb-0 border-primary' : 'mb-2 border-grey-mid'}`"
                   @click="onEditOfferPosition(cell.position.id)">
                {{ getQuantity(cell.position) }}
              </div>

              <div class="pr-8 pl-0 py-5 border-t border-b border-grey-mid mt-2" :class="`${cell.visible ? 'mb-0 border-primary' : 'mb-2 border-grey-mid'}`"
                   @click="onEditOfferPosition(cell.position.id)">
                {{ getUnitPrice(cell.position) }}
              </div>

              <div class="pr-4 pl-0 py-5 border border-l-0 mt-2 !text-right" :class="`${cell.visible ? 'mb-0 border-primary rounded-tr-md' : 'mb-2 border-grey-mid rounded-r-md'}`"
                   @click="onEditOfferPosition(cell.position.id)">
                {{ Utils.formatCurrency(calculateTotalCosts(cell.position.entries)) }}
                {{ TenantSettings.getCurrency() }}
              </div>

              <!-- Sub-Table for Position Entries -->
              <div v-if="cell.visible" :class="`${subtableColSpan(offer)} border-b border-l border-r rounded-b-md border-primary mb-2`" @click="toggleVisibility(index)">
                <!-- Header -->
                <div class="grid grid-cols-[3fr_1fr_1fr_1fr] auto-rows-[minmax(min-content,max-content)]">
                  <div class="contents items-center">
                    <div class="pl-5 pr-8 py-4 bg-blue-light">Beschreibung</div>
                    <div class="pr-8 py-4 bg-blue-light">Menge</div>
                    <div class="pr-8 py-4 bg-blue-light">Einheitspreis</div>
                    <div class="pr-5  py-4 text-right bg-blue-light">Total</div>
                  </div>
                  <!-- Content -->
                  <div v-for="entry of cell.position.entries" :key="entry.id" class="contents items-center">
                    <div class="pl-5 pr-8 py-4 border-t border-grey-mid">{{ entry.description }}</div>
                    <div class="pr-8 py-4 border-t border-grey-mid">{{ entry.quantity }} {{ Type.getUnit(entry.unitId).abbreviation() }}</div>
                    <div class="pr-8 py-4 border-t border-grey-mid">{{ Utils.formatCurrency(entry.unitPrice) }} {{ TenantSettings.getCurrency() }}/{{ Type.getUnit(entry.unitId).abbreviation() }}</div>
                    <div class="pr-5  py-4 border-t border-grey-mid text-right">{{ Utils.formatCurrency(entry.total ?? 0) }} {{ TenantSettings.getCurrency() }}</div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <!-- Mobile Table -->
        <div class="block md:hidden">
          <div v-if="tableOfferPositions" class="grid grid-cols-[1fr_auto] auto-rows-[minmax(min-content,max-content)]">
            <!-- Header -->
            <div class="contents mx-2 my-5 md:my-2 items-center">
              <div class="pl-3 pr-0 py-2 border border-r-0 rounded-l border-grey-mid bg-grey-mid mb-2 flex flex-row items-center">
                <div v-if="copyingAllowed" :class="`pr-2 transition-colors text-bodySmallNormal ${allChecked ? 'text-primary' : ''}`" @click="checkAll(!allChecked)">
                  <phosphor-icon :icon="allChecked ? 'check-square' : 'square'" :look="allChecked ? 'fill' : 'regular'" />
                </div>
                <div>
                  <div class="text-bodyMediumBold">Menge</div>
                  <div>Beschreibung</div>
                </div>
              </div>
              <div class="pr-4 pl-0 py-2 text-right border border-l-0 rounded-r border-grey-mid bg-grey-mid mb-2">
                <div class="text-bodyMediumBold">Einheitspreis</div>
                <div>Total</div>
              </div>
            </div>

            <div v-for="(cell, index) in tableOfferPositions" :key="index" class="contents mx-2 my-2 hover:cursor-pointer items-center">
              <!-- Content -->
              <div class="pl-3 pr-4 pt-5 pb-3 border border-r-0 border-grey-mid mt-2 flex flex-row items-center"
                   :class="`${cell.visible ? 'mb-0 border-primary rounded-tl' : 'mb-2 border-grey-mid rounded-l'}`">

                <div v-if="copyingAllowed" :class="`pr-2 transition-colors text-bodySmallNormal ${cell.checked ? 'text-primary' : ''}`" @click="checkCell(index)">
                  <phosphor-icon :icon="cell.checked ? 'check-square' : 'square'" :look="cell.checked ? 'fill' : 'regular'" />
                </div>

                <div>
                  <div class="flex flex-row" @click="Utils.isSingleOfferPosition(cell.position) ? onEditOfferPosition(cell.position.id) : toggleVisibility(index)">
                    <button class="py-0">
                      <base-icon class="h-5" v-if="!Utils.isSingleOfferPosition(cell.position)">
                        {{cell.visible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
                      </base-icon>
                    </button>
                    <div class="text-bodyMediumBold">
                      {{ getQuantity(cell.position) }}
                    </div>
                  </div>

                  <div @click="onEditOfferPosition(cell.position.id)">{{ cell.position.description }}</div>
                </div>
              </div>

              <div class="pr-4 pl-0 py-5 border border-l-0 mt-2 !text-right" :class="`${cell.visible ? 'mb-0 border-primary rounded-tr' : 'mb-2 border-grey-mid rounded-r'}`"
                   @click="onEditOfferPosition(cell.position.id)">
                <div class="text-bodyMediumBold">{{ getUnitPrice(cell.position) }}</div>
                <div>{{ Utils.formatCurrency(calculateTotalCosts(cell.position.entries)) }}
                  {{ TenantSettings.getCurrency() }}</div>
              </div>

              <!-- Line that has the full table length and is underneath the cells above -->
              <div v-if="cell.visible" class="col-span-2 border-b border-l border-r rounded-b border-primary mb-2" @click="toggleVisibility(index)">
                <!-- Header -->
                <div class="grid grid-cols-[1fr_auto_auto] auto-rows-[minmax(min-content,max-content)]">
                  <div class="contents items-center bg-grey-light">
                    <div class="pl-5 pr-8 py-4 bg-grey-light">Beschreibung</div>
                    <div class="pr-8 py-4 bg-grey-light">Menge</div>
                    <div class="pr-5  py-4 text-right bg-grey-light">Total</div>
                  </div>
                  <div v-for="entry of cell.position.entries" :key="entry.id" class="contents items-center">
                    <!-- Content -->
                    <div class="pl-5 pr-8 py-4 border-t border-grey-mid">{{ entry.description }}</div>
                    <div class="pr-8 py-4 border-t border-grey-mid">{{ entry.quantity }} {{ Type.getUnit(entry.unitId).abbreviation() }}</div>
                    <div class="pr-5  py-4 border-t border-grey-mid text-right">{{ Utils.formatCurrency(entry.total ?? 0) }} {{ TenantSettings.getCurrency() }}</div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="flex mt-2">
          <div v-if="totalPages > 1" class="flex flex-row ml-auto">
            <button class="mr-4 transition-colors hover:text-primary" @click="onPreviousPage"><base-icon>navigate_before</base-icon></button>
            <div class="cursor-default">{{offset + 1}} / {{totalPages}}</div>
            <button class="ml-4 transition-colors hover:text-primary" @click="onNextPage"><base-icon>navigate_next</base-icon></button>
          </div>
        </div>

      </div>
    </div>

  </base-container>

</template>

<script setup lang="ts">
import { ReportType, Type } from '@/model/Type'
import BaseIcon from '@/components/base/BaseIcon.vue'
import Spinner from '@/components/generel/Spinner.vue'
import { ref, watch } from 'vue'
import { getOfferPosition } from '@/service/offerPostionService'
import { Utils } from '../../client/utils'
import type { OfferPosition } from '@/model/OfferPosition'
import { useRoute, useRouter } from 'vue-router'
import { routeNames } from '@/router'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import type { OfferPositionEntry } from '@/model/OfferPositionEntry'
import { TenantSettings } from '../../stores/TenantSettings'
import type { Offer } from '@/model/Offer'
import { UrlData } from '@/client/UrlData'
import { ChapterDefault } from '@/model/Chapter'

const props = defineProps<{
  offer: Offer;
  projectId: string;
  locked: boolean;
  type: ReportType;
  linkedInvoiceId: string | undefined;
  copyingAllowed: boolean;
}>();

const router = useRouter();

const route = useRoute();
const preSelectedChapterId = route.query.chapterId as string;
const pageSize = 10;
const offset = ref(0);
const totalPages = ref(0);
const positionsLoading = ref(false);
const tableOfferPositions = ref<{position: OfferPosition, visible: boolean, checked: boolean}[]>([]);
const allChecked = ref(false);
const chapterId = ref(preSelectedChapterId ?? 'all');
let dataInitialized = false;

function tableColumns(offer: Offer) {
  if (props.copyingAllowed)
    if (offer.chapters.length > 0) return 'grid-cols-[auto_auto_auto_1fr_auto_auto_auto]';
    else return 'grid-cols-[auto_auto_1fr_auto_auto_auto]';
  else
    if (offer.chapters.length > 0) return 'grid-cols-[auto_auto_1fr_auto_auto_auto]';
    else return 'grid-cols-[auto_1fr_auto_auto_auto]';
}

function subtableColSpan(offer: Offer) {
  if (props.copyingAllowed)
    if (offer.chapters.length > 0) return 'col-span-7';
    else return 'col-span-6';
  else
    if (offer.chapters.length > 0) return 'col-span-6';
    else return 'col-span-5';
}

function checkAll(value: boolean) {
  allChecked.value = value;
  tableOfferPositions.value.forEach(p => p.checked = value);
}

function checkCell(index: number) {
  tableOfferPositions.value[index].checked = !tableOfferPositions.value[index].checked
}

async function onEditOfferPosition(id: string) {
  console.log(props.offer.chapters);
  if (props.locked) return;
  await router.push({
    name: routeNames.EDIT_OFFER_POSITION, 
    params: {id: id}, 
    query: {
      offerId: props.offer.id, 
      projectId: props.projectId, 
      type: props.type,
      chapters: UrlData.toUrlData(props.offer.chapters)
    }});
}

async function onAddOfferPosition() {
  await router.push({
    name: routeNames.CREATE_OFFER_POSITION, 
    query: {
      offerId: props.offer.id, 
      projectId: props.projectId, 
      type: props.type,
      chapters: UrlData.toUrlData(props.offer.chapters)
    }});
}

function getQuantity(position: OfferPosition) {
  const firstUnit = position.entries[0].unitId;

  for (const entry of position.entries) if (entry.unitId != firstUnit) return 'divers'

  return `${position.entries.reduce((sum, entry) => sum + entry.quantity, 0)} ${Type.getUnit(firstUnit).abbreviation()}`;
}

function getUnitPrice(position: OfferPosition) {
  const firstPrice = position.entries[0].unitPrice
  const firstUnit = position.entries[0].unitId;

  for (const entry of position.entries) if (entry.unitId != firstUnit || entry.unitPrice != firstPrice) return 'divers'

  return `${Utils.formatCurrency(firstPrice)} ${TenantSettings.getCurrency()}/${Type.getUnit(firstUnit).abbreviation()}`;
}

function calculateTotalCosts(entries: OfferPositionEntry[]) {
  return entries.reduce((sum, entry) => sum + (entry.total ?? 0), 0);
}

function toggleVisibility(index: number): void {
  if (!tableOfferPositions.value) return;
  for (let i = 0; i < tableOfferPositions.value?.length; i++) {
    if (i == index) tableOfferPositions.value[i].visible = !tableOfferPositions.value[i].visible
    else tableOfferPositions.value[i].visible = false;
  }
}

async function onNextPage() {
  if (offset.value + 1 >= totalPages.value) return;
  offset.value += 1;
  checkAll(false);
  await loadOfferPositions();
}

async function onPreviousPage() {
  if (offset.value == 0) return;
  offset.value -= 1;
  checkAll(false);
  await loadOfferPositions()
}

async function loadOfferPositions(setLoading: boolean = false) {
  if (setLoading) positionsLoading.value = true;
  const response = await getOfferPosition(props.offer.id, props.type, pageSize, offset.value, chapterId.value);
  positionsLoading.value = false;
  if (!response) return;
  totalPages.value = response.totalPages;
  tableOfferPositions.value = response.page.map(w => {
    return {
      position: w,
      visible: false,
      checked: false
    }
  });
  dataInitialized = true;
}

watch(() => props.offer, newVal => {
  if (!newVal) return;
  offset.value = 0;
  if (dataInitialized) {
    dataInitialized = false;
    chapterId.value = 'all';
  }
  loadOfferPositions(true);
}, {
  immediate: true
});

watch(() => chapterId.value, newVal => {
  if (!newVal || !dataInitialized) return;
  router.replace({
    path: route.path,
    query: {
      ...route.query,
      chapterId: newVal
    }
  });
  loadOfferPositions(true);
}, {
  immediate: true
});

function emitChecked() {
  if (!props.linkedInvoiceId) {
    console.error('No linked invoice to copy');
    return;
  }

  return tableOfferPositions.value
    .filter(cell => cell.checked)
    .map(cell => cell.position);
}

defineExpose({
  emitChecked,
  checkAll
})

</script>

<style scoped>
.row:hover > div {
  border-color: #001aea;
  background-color: #f5f7ff;
  box-shadow: inset 0 6px 6px -6px rgba(0, 0, 0, 0.1),  /* Bottom shadow */
  inset 0 -6px 6px -6px rgba(0, 0, 0, 0.1); /* Top shadow */
}

.row > div {
  transition: box-shadow 0.15s ease, color 0.15s ease, border-color 0.15s ease;
}

</style>