import { ref, computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import type Company from '@/model/Company';

export const useCompanyStore = defineStore('company', () => {
  
  const company = ref<Company | null>(null);
  
  const getCompany = computed(() => company);

  function setCompany(newCompany: Company) {
    company.value = newCompany;
  }

  function $reset() {
    company.value = null;
  }

  return { company, getCompany, $reset, setCompany }

})