<template>

  <base-site :title="titleText" :show-button="!!reportPositionId" button-icon="trash" button-look="secondary-light" @button-action="openModal">

    <spinner v-if="reportPositionLoading" />

    <template v-else>

      <base-container v-if="chapters.length != 0" class="mb-betweenElements">
        <FormKit v-if="chapters.length != 0" type="select" label="Phase" :options="chapterOptions" v-model="chapterId" />
      </base-container>

      <div class="flex flex-row space-x-0 h-fit">
        <button :class="`${single ? 'border-b-white hover:cursor-default' : 'text-grey-dark hover:text-primary'} !pl-outerFrameBorder transition-colors md:p-4 p-6 flex rounded-r-none rounded-b-none md:rounded-tl-md rounded-none w-full items-center bg-white border-1 border-grey-mid`" @click="setSingle(true)">
          <phosphor-icon icon="minus" look="regular" />
          <span class="h-full text-center ml-3">Einzelposition</span>
        </button>
        <button :class="`${single ? 'text-grey-dark hover:text-primary' : 'border-b-white hover:cursor-default'} !pl-outerFrameBorder transition-colors md:p-4 p-6 flex md:rounded-tr-md w-full items-center border-l-0 bg-white border-1 border-grey-mid`" @click="setSingle(false)">
          <phosphor-icon icon="list" look="regular" />
          <span class="h-full text-center ml-3">Mehrfachposition</span>
        </button>
      </div>
      <base-container class="mb-betweenElements !rounded-t-none !border-t-0">

        <div v-if="single" class="flex justify-between mb-betweenElements">
          <base-button look="secondary" type="icon" class="ml-auto h-fit rounded-lg" @click="openCategoryModal">
            <phosphor-icon icon="folder-open" />
            <span class="ml-5">{{ type == ReportType.WORK ? 'Pauschale Arbeit wählen' : 'Material wählen' }}</span>
          </base-button>
        </div>

      <category-card class="mb-betweenElements"
                                       ref="descriptionCard"
                                       :existing="existingPosition"
                                       :type="type"
                                       :single="single"
                                       @receive-description="receiveDescriptionFromDescriptionCard" />

      <base-heading v-if="!single" type="h2" class=" pb-4 mt-5 !mb-0">Unterpositionen</base-heading>

      <entries-card class="mb-betweenElements"
                    ref="entriesCard"
                    :existing-entries="existingPosition?.entries ?? []"
                    :type="type"
                    :single="single"
                    @receive-entries="receiveEntries"/>

      </base-container>

      <div class="flex md:flex-row flex-col">
        <base-button class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
          <spinner-small v-if="requesting" />
          <div v-else>{{submitText}}</div>
        </base-button>
        <base-button :class="`md:ml-auto md:mx-0 mx-betweenElements md:w-buttonXLarge mt-8 md:mt-0`" look="secondary-light" @click="onCancel">
          Abbrechen
        </base-button>
      </div>

      <categories-modal ref="categoryModal" :type="type" @receive-modal-category="receiveCategory"></categories-modal>
      <confirmation-modal ref="confirmDeletionModal"
                          title="Offertenposition Löschen"
                          confirm-text="Löschen"
                          @on-confirm="onDelete">
        Soll die Offertenposition wirklich gelöscht werden?
      </confirmation-modal>

    </template>
  </base-site>

</template>

<script setup lang="ts">

import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { API, PathSegment } from '@/client/axios'
import router from '@/router'
import BaseButton from '@/components/base/BaseButton.vue'
import { useToast } from 'vue-toast-notification'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import Spinner from '@/components/generel/Spinner.vue'
import BaseSite from '@/components/base/BaseSite.vue'
import type Category from '@/model/Category'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import { ReportType } from '@/model/Type'
import type { OfferPosition, OfferPositionRequest } from '@/model/OfferPosition'
import OfferPositionDescriptionCard from '@/components/offerPosition/offerPositionDescriptionCard.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import EntriesCard from '@/components/workReportPosition/EntriesCard.vue'
import CategoriesModal from '@/components/workReportPosition/CategoriesModal.vue'
import type { ReportPositionEntry } from '@/model/ReportPositionEntry'
import type { OfferPositionEntry } from '@/model/OfferPositionEntry'
import { Utils } from '@/client/utils'
import CategoryCard from '@/components/workReportPosition/CategoryCard.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import type { Chapter } from '@/model/Chapter'
import { UrlData } from '@/client/UrlData'

const OFFER_POSITION = 'Offertenposition';
const route = useRoute();

const requesting = ref(false);
const reportPositionLoading = ref(true);
const reportPositionId = route.params.id as string;
const offerId = route.query.offerId as string;
const projectId = route.query.projectId as string;
const type = route.query.type as ReportType;
const existingPosition = ref<OfferPosition>();
const chapters = (route.query.chapters ? UrlData.toData<Chapter[]>(route.query.chapters as string) : []);
const chapterOptions = ref<{label: string; value: string}[]>([]);

const chapterId = ref<string>('');

const titleText = ref(OFFER_POSITION);
const submitText = ref<string>();

const descriptionCard = ref(OfferPositionDescriptionCard)
const description = ref('');

const entriesCard = ref(EntriesCard);
const entries = ref<OfferPositionEntry[]>([]);

const confirmDeletionModal = ref(ConfirmationModal);

const $toast = useToast();

const single = ref(true);

function receiveDescriptionFromDescriptionCard(text: string) {
  description.value = text;
}

function getDescription() {
  if (descriptionCard.value) {
    descriptionCard.value.emitDescription();
  }
}

function receiveEntries(emittedEntries: ReportPositionEntry[]) {
  entries.value = emittedEntries;
  console.log(emittedEntries);
}

function getEntries() {
  if (entriesCard.value) {
    entriesCard.value.emitForms();
  }
}

function setSingle(value: boolean) {
  single.value = value;
}

async function onSubmit() {
  getDescription();
  getEntries();
  if (description.value == '') {
    $toast.error('Keine Beschreibung hinzugefügt');
    return;
  }
  if (entries.value.length <= 0) {
    $toast.error('Kein Positionen hinzugefügt');
    return;
  }
  if (!offerId) {
    $toast.error('Keine Offerte zugewiesen');
    console.error('Offer not selected!');
    return;
  }
  if (reportPositionId) await onUpdate();
  else await onCreate();
}

async function onCreate() {
  if (requesting.value) return;
  requesting.value = true;
  const response = await API.createDataObject<OfferPosition, OfferPositionRequest>(PathSegment.OFFER_POSITIONS,
    {
        offerId: offerId,
        description: description.value,
        chapterId: chapterId.value == '' ? undefined : chapterId.value,
        reportType: type,
        entries: entries.value,
      }, OFFER_POSITION);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onUpdate() {
  if (requesting.value) return;
  if (!reportPositionId) {
    $toast.error('Aktuallisieren nicht möglich, keine Offerten ID vorhanden');
    console.error('Update failed: OfferPositionId not set');
    return;
  }

  requesting.value = true;
  const response = await API.updateDataObject<OfferPosition, OfferPositionRequest>(PathSegment.OFFER_POSITIONS,
    {
      id: reportPositionId,
      offerId: offerId,
      description: description.value,
      reportType: type,
      chapterId: chapterId.value == '' ? undefined : chapterId.value,
      entries: entries.value,
    }, OFFER_POSITION);
  if (response) router.go(-1);
  requesting.value = false;
}

async function loadOfferPosition() {
  const response = await API.getDataObject<OfferPosition>(PathSegment.OFFER_POSITIONS, reportPositionId)
  if (!response) return;

  existingPosition.value = response;
  description.value = response.description;
  chapterId.value = response.chapterId ?? '';

  single.value = Utils.isSingleOfferPosition(response);

  reportPositionLoading.value = false;
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.OFFER_POSITIONS, reportPositionId, OFFER_POSITION);
  if (success) router.go(-1);
  reportPositionLoading.value = false;
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

const categoryModal = ref(CategoriesModal);
const openCategoryModal = () => {
  if (categoryModal.value) {
    categoryModal.value.openModal();
  }
};

function receiveCategory(category: Category) {
  if (descriptionCard.value) {
    descriptionCard.value.setDescription(category.invoiceInscription);
  }
  if (entriesCard.value) {
    entriesCard.value.receiveCategory(category);
  }
}

onMounted(async () => {
  if (reportPositionId) {
    // edit
    titleText.value = `${OFFER_POSITION} bearbeiten`;
    submitText.value = 'Speichern';
    await loadOfferPosition();
  } else {
    // create
    titleText.value = `${OFFER_POSITION} erstellen`;
    submitText.value = 'Erfassen';
    reportPositionLoading.value = false;
  }
  if (chapters.length == 0) return;
  chapterOptions.value = [{label: 'Keine Phase', value: ''}, ...chapters.map((c => {return {label: `${c.index + 1}. ${c.name}`, value: c.id}}))];
});

</script>

<style scoped>

</style>