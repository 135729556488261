<template>
  <base-container>
    <div class="flex flex-row">
      <base-heading>
        <div class="title-icon-with-background">
          <phosphor-icon icon="map-pin" />
        </div>
        Lade- & Abladeort
      </base-heading>
      <button class="ml-auto transition-colors hover:text-primary" @click="openTranscriptionModal">
        <phosphor-icon icon="microphone" />
      </button>
    </div>

    <div class="flex flex-row items-center">
      <div class="w-full">
        <FormKit type="text" label="Ladeort" v-model="pickupLocation" />
      </div>
      <button class="ml-betweenElements w-10 hover:text-primary transition-colors" @click="locateOrCorrect(TransportTask.PICKUP)">
        <spinner-small-primary v-if="pickupLocating" />
        <phosphor-icon v-else icon="map-pin" />
      </button>
    </div>
    <div class="flex flex-row items-center">
      <div class="w-full">
        <FormKit type="text" label="Abladeort" v-model="dropLocation" :classes="{outer: '!mb-0'}" />
      </div>
      <button class="ml-betweenElements w-10 hover:text-primary transition-colors" @click="locateOrCorrect(TransportTask.DROP)">
        <spinner-small-primary v-if="dropLocating" />
        <phosphor-icon v-else icon="map-pin" />
      </button>
    </div>

    <transcription-modal
      ref="transcriptionModalRef"
      existing-text=""
      @on-transcribed="onTranscribed"
    />
  </base-container>
</template>

<script setup lang="ts">
import BaseContainer from '@/components/base/BaseContainer.vue'
import { ref, watch } from 'vue'
import TranscriptionModal from '@/components/workReportPosition/TranscriptionModal.vue'
import { Utils } from '@/client/utils'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { useToast } from 'vue-toast-notification'
import SpinnerSmallPrimary from '@/components/generel/SpinnerSmallPrimary.vue'

const props = defineProps<{
  existingDescription: string
}>()

const emits = defineEmits(['receive-locations']);

const pickupLocation = ref('');
const dropLocation = ref('');

const pickupLocating = ref(false);
const dropLocating = ref(false);

const $toast = useToast();

const transcriptionModalRef = ref(TranscriptionModal);
const openTranscriptionModal = () => {
  if (transcriptionModalRef.value) {
    transcriptionModalRef.value.openModal();
  }
};

function onTranscribed(text: string) {
  if (!text || text == '') return;
  const split = getPickupAndDropByText(text);
  pickupLocation.value = split.pickup;
  dropLocation.value = split.drop;
}

watch(() => props.existingDescription, newVal => {
  if (!newVal) return;
  const locations = Utils.getPickupAndDropLocation(newVal);
  pickupLocation.value = locations.pickup;
  dropLocation.value = locations.drop;
}, {
  immediate: true
});

watch(() => pickupLocation.value, newVal => {
  if (newVal == undefined) return;
  emitData()
}, {
  immediate: true
});

watch(() => dropLocation.value, newVal => {
  if (newVal == undefined) return;
  emitData()
}, {
  immediate: true
});

function emitData() {
  emits('receive-locations', `;;;${pickupLocation.value};;;${dropLocation.value}`);
}

function getPickupAndDropByText(text: string) {
  if (text.includes(' bis nach ')) return splitPickupAndDropByKeyword(text, ' bis nach ');
  if (text.includes(' nach ')) return splitPickupAndDropByKeyword(text, ' nach ');
  if (text.includes(' bis ')) return splitPickupAndDropByKeyword(text, ' bis ');
  if (text.includes(' zu ')) return splitPickupAndDropByKeyword(text, ' zu ');
  if (text.includes(' Nach ')) return splitPickupAndDropByKeyword(text, ' Nach ');
  if (text.includes(' Bis ')) return splitPickupAndDropByKeyword(text, ' Bis ');
  if (text.includes(' Bis nach ')) return splitPickupAndDropByKeyword(text, ' Bis nach ');
  if (text.includes(' Zu ')) return splitPickupAndDropByKeyword(text, ' Zu ');
  else return {pickup: text, drop: ''};
}

function splitPickupAndDropByKeyword(text: string, keyword: string) {
  const split = text.split(keyword);
  if (split.length < 2) return {pickup: removeStartingVon(text), drop: ''};
  return {pickup: removeEndingDot(removeStartingVon(split[0])), drop: removeEndingDot(split[1])}
}

function removeEndingDot(str: string): string {
  if (str.endsWith('.')) {
    return str.slice(0, -1);
  }
  return str;
}

function removeStartingVon(str: string): string {
  const prefix = 'Von';
  if (str.startsWith(prefix)) {
    return str.slice(prefix.length).trim();
  }
  return str;
}




async function locateOrCorrect(task: TransportTask) {
  if (task == TransportTask.PICKUP) {
    if (pickupLocation.value) await correctPickupLocation();
    else await locatePickupLocation();
  } else {
    if (dropLocation.value) await correctDropLocation();
    else await locateDropLocation();
  }
}

async function locatePickupLocation() {
  if (pickupLocating.value) return;
  try {
    pickupLocating.value = true;
    const coordinates = await getLocation();
    if (!coordinates) return;
    pickupLocation.value = await sendCoordinates(coordinates.latitude, coordinates.longitude);
  } finally {
    pickupLocating.value = false;
  }
}

async function locateDropLocation() {
  if (dropLocating.value) return;
  try {
    dropLocating.value = true;
    const coordinates = await getLocation();
    if (!coordinates) return;
    dropLocation.value = await sendCoordinates(coordinates.latitude, coordinates.longitude);
  } finally {
    dropLocating.value = false;
  }
}

async function correctPickupLocation() {
  if (pickupLocating.value) return;
  try {
    pickupLocating.value = true;
    const coordinates = await getLocation();
    if (!coordinates) return;
    pickupLocation.value = await sendLocationData(pickupLocation.value, coordinates.latitude, coordinates.longitude);
  } finally {
    pickupLocating.value = false;
  }
}

async function correctDropLocation() {
  if (dropLocating.value) return;
  try {
    dropLocating.value = true;
    const coordinates = await getLocation();
    if (!coordinates) return;
    dropLocation.value = await sendLocationData(dropLocation.value, coordinates.latitude, coordinates.longitude);
  } finally {
    dropLocating.value = false;
  }
}

async function sendCoordinates(latitude: number, longitude: number) {
  const resp = await API.post<string>(PathSegment.LOCATIONS_LOCATE, {
    latitude: latitude,
    longitude: longitude
  });

  if (resp.key != ResponseKey.OK || !resp.data) {
    $toast.error(`Lokalisierung fehlgeschlagen: ${resp.message}`);
    return "";
  }

  return resp.data;
}

async function sendLocationData(locationText: string, latitude: number, longitude: number) {
  const resp = await API.post<string>(PathSegment.LOCATIONS_CORRECT, {
    text: locationText,
    latitude: latitude,
    longitude: longitude
  });

  if (resp.key != ResponseKey.OK || !resp.data) {
    $toast.error(`Korrektur fehlgeschlagen: ${resp.message}`);
    return locationText;
  }

  return resp.data;
}

async function getLocation(): Promise<{ latitude: number; longitude: number } | null> {
  if (navigator.geolocation) {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        },
        (err) => {
          switch (err.code) {
            case err.PERMISSION_DENIED:
              $toast.info('Um eine Bessere Ortskorrektur zu ermöglichen muss der Zugriff auf den Standort gegeben werden');
              console.error('User denied the request for Geolocation.');
              break
            case err.POSITION_UNAVAILABLE:
              $toast.info('Standort ist nicht verfügbar');
              console.error('Location information is unavailable.');
              break
            case err.TIMEOUT:
              $toast.info('Anfrage hat das Zeitlimit überschritten');
              console.error('The request to get user location timed out.');
              break
            default:
              $toast.error('Ein unbekannter Fehler ist aufgetreten');
              console.error('An unknown error occurred.');
              break
          }
          resolve(null)
        }
      )
    })
  } else {
    $toast.error('Standort wird von dem Browser nicht unterstützt');
    console.error('Geolocation is not supported by this browser.');
    return null
  }
}

enum TransportTask {
  PICKUP,
  DROP
}

</script>

<style scoped></style>