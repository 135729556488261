<template>
  <div v-if="isVisible" class="modal-container" @click="closeModal">
    <div class="modal-tall-content flex flex-col" @click.stop>

      <div class="flex items-center bg-grey-mid rounded-lg overflow-hidden mt-5 min-h-13">
        <span class="flex items-center px-3">
            <base-icon>search</base-icon>
        </span>
        <input type="text" class="w-full py-2 px-4 bg-grey-mid border-0 text-bodyLargeNormal focus:outline-none focus:ring-0" placeholder="Kategoriensuche" v-model="search" @input="onSearch">
        <span v-if="search" class="flex items-center px-3 hover:cursor-pointer">
            <base-icon @click="onClearSearch">clear</base-icon>
        </span>
      </div>

      <spinner v-if="categoriesLoading" />

      <div v-if="!categoriesLoading && showCategoryTypes">
        <div v-for="(categoryType, index) of Type.getAllCategoryTypes()" :key="index">
          <button class="h-categoryButtonY my-6 selectable-lg dynamic-style flex text-categoryButton items-center w-full" v-if="categoryType.reportType == type" @click="displayRootCategories(categoryType.id)" :style="`--dynamic-border-color: ${categoryType.displayColor}`" >
            {{categoryType.name}}</button>
        </div>
      </div>

      <div v-if="!categoriesLoading && !showCategoryTypes" class="overflow-y-auto flex-grow" style="padding-bottom: 130px">
        <div v-if="categories.length > 0">
          <button class="h-categoryButtonY  my-6 selectable-lg flex flex-row dynamic-style items-center w-full" v-for="(category, index) of categories" :key="index" @click="displayCategoryChildren(category)" :style="`--dynamic-border-color: ${Type.getCategoryType(category.categoryTypeId).displayColor}`" >
            <i class="ph-bold text-titleLarge mr-5" :class="category.icon == '' ? '' : `ph-${category.icon}`"></i>
            <div class="text-categoryButton">{{category.name}}</div>
          </button>
        </div>
        <div v-else class="text-grey-dark text-bodyLargeNormal">Keine Kategorien vorhanden</div>
      </div>

      <div class="absolute inset-x-0 bottom-0 bg-white md:rounded-md">
        <div class="flex border-b-2 border-grey-dark px-3 items-center min-h-categoryNavigation">
          <button>
            <base-icon class="transition-colors text-iconSizeLarge hover:cursor-pointer hover:text-primary" @click="onBack">
              arrow_back
            </base-icon>
          </button>
          <div ref="pathContainer" class="flex flex-row overflow-x-auto pb-2 mt-5 items-center">
            <div v-for="(category, index) in selectedCategoryPath" :key="index" class="p-2 flex-shrink-0 text-bodyLargeNormal">
              <button v-if="index == selectedCategoryPath.length - 1" class="border-2 border-primary p-4 rounded" @click="onSubmit">{{category.name}}</button>
              <div v-else>{{`${category.name} /`}}</div>
            </div>
          </div>
        </div>

        <base-button class="m-3" look="secondary" @click="closeModal">
          Abbrechen
        </base-button>
      </div>

    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, ref } from 'vue'
import type Category from '@/model/Category'
import { ReportType, Type } from '@/model/Type'
import { loadCategoryChildren, loadRootCategories } from '@/service/categoryService'
import BaseButton from '@/components/base/BaseButton.vue'
import { useToast } from 'vue-toast-notification'
import Spinner from '@/components/generel/Spinner.vue'
import { InvoiceInscriptionBuilder } from '@/client/InvoiceInscriptionBuilder'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { API, PathSegment } from '@/client/axios'
import { ResponseKey } from '@/model/ResponseWrapper'
import { Utils } from '@/client/utils'

const props = defineProps<{
  type: ReportType
}>();

const isVisible = ref<boolean>(false);

const showCategoryTypes = ref(false);
const categories = ref<Category[]>([]);
const categoriesLoading = ref(true);
const selectedCategoryPath = ref<Category[]>([]);
const search = ref('');

const emits = defineEmits(['receive-modal-category']);

const $toast = useToast();

const pathContainer = ref<any>(null);
const invoiceInscriptionContainer = ref<any>(null);

const openModal = (categories: Category[]): void => {
  isVisible.value = true;
  const categoryTypes = Type.getAllCategoryTypes().filter(t => t.reportType == props.type);
  if (categoryTypes.length == 1) displayRootCategories(categoryTypes[0].id);
  else {
    showCategoryTypes.value = true;
    categoriesLoading.value = false;
  }
};

function onSearch(input: any) {
  if (input.target.value == '') {
    onClearSearch();
    return;
  }
  search.value = input.target.value;
  displaySearchCategories(search.value);
}

function onClearSearch() {
  search.value = '';
  categories.value = []
  showCategoryTypes.value = true;
}

const closeModal = (): void => {
  showCategoryTypes.value = false;
  categories.value = [];
  selectedCategoryPath.value = [];
  isVisible.value = false;
  search.value = '';
};

function onBack() {
  if (selectedCategoryPath.value.length == 0) {
    showCategoryTypes.value = true;
  } else if (getSelectedCategory().categoryLevel == 0) {
    displayRootCategories(getSelectedCategory().categoryTypeId);
    selectedCategoryPath.value.pop();
  } else if (selectedCategoryPath.value.length > 1) {
    selectedCategoryPath.value.pop();
    displayCategoryChildren(getSelectedCategory(), false);
  } else {
    $toast.error('error on back click')
  }
}

function onSubmit() {
  if (selectedCategoryPath.value.length == 0) $toast.error('Keine Kategorie ausgewählt');
  emits('receive-modal-category', getSelectedCategory());
  closeModal();
}

async function displayRootCategories(categoryTypeId: string) {
  showCategoryTypes.value = false;
  categoriesLoading.value = true;
  categories.value = await loadRootCategories(categoryTypeId);
  categoriesLoading.value = false;

  if (categories.value.length == 0) {
    $toast.error('Keine Kategorien verfügbar');
    showCategoryTypes.value = true;
  }
}

function getSelectedCategory(): Category {
  return selectedCategoryPath.value[selectedCategoryPath.value.length - 1];
}

async function displayCategoryChildren(category: Category, addToPath: boolean = true) {
  showCategoryTypes.value = false;

  if (addToPath) {
    selectedCategoryPath.value.push(category);
    if (pathContainer.value) {
      await nextTick();
      pathContainer.value.scrollLeft = pathContainer.value.scrollWidth;
    }
    if (invoiceInscriptionContainer.value) {
      await nextTick();
      invoiceInscriptionContainer.value.scrollLeft = invoiceInscriptionContainer.value.scrollWidth;
    }
  }

  categoriesLoading.value = true;
  categories.value = await loadCategoryChildren(category.id);
  categoriesLoading.value = false;

  if (categories.value.length == 0) await onSubmit()
}

async function displaySearchCategories(searchTerm: string) {
  showCategoryTypes.value = false;
  try {
    const response = await API.getWithParameters<Category[]>(PathSegment.CATEGORIES, new Map<string,string>([['reportType', props.type], ['searchTerm', searchTerm]]));
    if (response.key == ResponseKey.OK) {
      categories.value = response.data;
    } else {
      $toast.error(`Suchanfrage Fehler: ${response.message}`);
    }
  } catch (error) {
    $toast.error(`Suchanfrage Fehler: ${Utils.getError(error)}`);
  }
}

defineExpose({
  openModal,
  closeModal
});
</script>

<style scoped>

.dynamic-style {
  border-color: var(--dynamic-border-color);
  background-color: var(--dynamic-border-color); /* Default background color for small screens */
}

@media (min-width: 768px) { /* Apply hover effect only on md screens and larger */
  .dynamic-style {
    background-color: transparent; /* Reset background color for larger screens */
  }

  .dynamic-style:hover {
    background-color: var(--dynamic-border-color);
  }
}

</style>
