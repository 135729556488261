<template>
    <div class="flex h-screen w-screen justify-center items-center">
        <div class="max-w-none xs:max-w-[400px] sm:max-w-[500px] px-outerFrameBorder py-outerFrameBorder xs:p-[4rem] w-screen sm:w-auto border xs:rounded-md border-grey-mid bg-white">
            <base-heading class="text-[1.5rem] leading-tight font-bold !mb-betweenElements">Melde dich mit deinem Benutzerkonto an</base-heading>
            <!-- <p class="mb-outerFrameBorder">Melde dich mit deinem ReportPro Konto an.</p> -->
            <FormKit type="form" submit-label="Anmelden" @submit="onSubmit" :config="{ validationVisibility: 'submit' }">
                <FormKit
                  type="text"
                  name="email"
                  id="email"
                  label="E-Mail*"
                  validation="required|email"
                  v-model="formState.email"
                />
                <FormKit
                  type="password"
                  name="password"
                  id="password"
                  label="Password*"
                  validation="required"
                  v-model="formState.password"
                />
                <p class="text-bodySmallNormal text-red" v-if="loginFailed">{{ failMessage }}</p>
                <div class="mb-betweenElements flex justify-between items-center">
                  <FormKit
                  type="checkbox"
                  name="rememberMe"
                  id="rememberMe"
                  label="Eingeloggt bleiben"
                  v-model="formState.rememberMe"
                />
                <p class="text-bodySmallNormal underline text-primary">Password vergessen?</p>
                </div>
            </FormKit>
            <hr class="my-outerFrameBorder"/>
            <div class="flex justify-between items-center">
              <p class="text-bodySmallBold">Copyright 2024 ©</p>
              <p class="uppercase text-bodySmallBold">ReportPro</p>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { API } from '@/client/axios';
import { reactive, ref } from 'vue';
import type KeycloakTokenResponse from '@/model/KeycloakTokenResponse';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router'

const router = useRouter();
const loginFailed = ref(false);
const failMessage = ref("");
const keycloakAuthenticationError = 'invalid_grant';

const authenticationErrorMessage = 'E-Mailadresse oder Passwort falsch';
const unknownLoginError = 'Unbekannter Anmeldefehler';

const formState = reactive({
  email: '',
  password: '',
  rememberMe: false,
});

async function onSubmit() {
  // const authResponse = await API.authenticate(formState.email, formState.password);
  //
  // if (authResponse.success) {
  //   API.setJwtToken(authResponse.access_token);
  //   if (formState.rememberMe) {
  //     putTokenInCookie(authResponse);
  //   }
  //   await router.push({ name: routeNames.INIT });
  // } else {
  //   displayInvalidCredentials(authResponse);
  // }
}

function putTokenInCookie(tokenResponse: KeycloakTokenResponse) {
  // Cookies.set('jwtToken', tokenResponse.access_token, { expires: 7, secure: true, sameSite: 'Strict' });
}

function displayInvalidCredentials(tokenResponse: KeycloakTokenResponse) {
  // failMessage.value = tokenResponse.error === keycloakAuthenticationError
  // ? authenticationErrorMessage
  // : unknownLoginError
  //
  // loginFailed.value = true;
}

</script>