<template>

  <base-site title="Kategorien">
    <base-container>

      <spinner v-if="!initialized" />

      <div v-else>
        <div class="flex flex-row h-11 m-3 overflow-x-auto items-center">
          <div v-if="currentType" class="p-2 flex-shrink-0 text-bodyLargeNormal"> <!--          @click="routerJump(currentType.id); jumpToRoot(currentType)"-->
            {{ currentType.name }}
          </div>
          <div v-for="category of selectedCategoryPath" :key="category.id" class="p-2 flex-shrink-0 text-bodyLargeNormal">  <!--          @click="routerJump(category.id); jumpTo(category)"-->
            {{''}} / {{category.name}}
          </div>
        </div>

        <base-heading type="h2" class="border-b border-primary pb-4 mt-7 !mb-10 text-primary"></base-heading>

        <spinner v-if="categoriesLoading" />

        <div v-else-if="showCategoryTypes">
          <div v-for="categoryType of Type.getAllCategoryTypes()" :key="categoryType.id">
            <div class="h-categoryButtonY my-6 selectable-lg dynamic-style flex text-categoryButton items-center" :style="`--dynamic-border-color: ${categoryType.displayColor}`" @click="routerNext(categoryType.id)">
              {{categoryType.name}}
            </div>
          </div>
        </div>

        <div v-else class="overflow-y-auto">
          <div class="h-categoryButtonY mb-6 selectable-lg flex flex-row dynamic-style items-center" v-for="category of categories" :key="category.id" :style="`--dynamic-border-color: ${Type.getCategoryType(category.categoryTypeId).displayColor}`" @click="routerNext(category.id); displayCategoryChildren(category)">
            <i class="ph-bold text-titleLarge mr-5" :class="category.icon == '' ? '' : `ph-${category.icon}`"></i>
            <div class="text-categoryButton">{{category.name}}</div>
            <button class="ml-auto">
              <base-icon @click.stop class="transition-colors hover:text-white" @click="onEditCategory(category)">edit</base-icon>
            </button>
            <button class="ml-5">
              <base-icon class="transition-colors hover:text-white">arrow_forward_ios</base-icon>
            </button>
          </div>
          <base-button look="secondary" v-if="currentType" class="w-full rounded-md" @click="onAddCategory()">
            <base-icon>add</base-icon>
          </base-button>
        </div>
      </div>

    </base-container>
  </base-site>

</template>

<script setup lang="ts">

import BaseSite from '@/components/base/BaseSite.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import { type CategoryType, Type } from '@/model/Type'
import { ref, watch } from 'vue'
import type Category from '@/model/Category'
import { loadCategoryChildren, loadRootCategories } from '@/service/categoryService'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import router, { routeNames } from '@/router'
import { useRoute } from 'vue-router'
import { API, PathSegment } from '@/client/axios'
import Spinner from '@/components/generel/Spinner.vue'

const route = useRoute();
const showCategoryTypes = ref(false);
const categories = ref<Category[]>([]);
const categoriesLoading = ref(false);
const selectedCategoryPath = ref<Category[]>([]);
const currentType = ref<CategoryType | null>(null);
const initialized = ref(true);

watch(() => route.path, async newVal => {
  if (!newVal) return;
  const ids = getPathIdsAsArray();

  initialized.value = true;
  if (ids.length == 0) displayRoot();
  else if (ids.length == 1) await displayCategoryTypeChildren(ids[0]);
  else if (ids.length - 1 < selectedCategoryPath.value.length) await onBackNew(ids);
  else if (ids.length - 1 == selectedCategoryPath.value.length) await displayChildren(ids);
  else if (ids.length - 1 > selectedCategoryPath.value.length) {
    initialized.value = false;
    await catchUpToPath(ids);
  }

}, {
  immediate: true
});

async function catchUpToPath(ids: string[]) {
  if (ids.length == 0) {
    displayRoot();
    return;
  }

  currentType.value = Type.getCategoryType(ids[0]);
  if (ids.length == 1) {
    await displayCategoryTypeChildren(currentType.value.id);
    return;
  }

  for (let i = 1; i < ids.length; i++) {
    const id = ids[i];
    const response = await API.getDataObject<Category>(PathSegment.CATEGORIES, id);
    if (!response) break;
    selectedCategoryPath.value.push(response);
  }
  await displayCategoryChildren(getSelectedCategory(), false);
  initialized.value = true;
}

async function displayChildren(ids: string[]) {
  if (selectedCategoryPath.value.length == 0) {
    currentType.value = Type.getCategoryType(ids[0]);
    await displayCategoryTypeChildren(ids[0]);
    return;
  }

  const category = categories.value.find(c => c.id == ids[ids.length - 1]);
  if (!category) return;
  await displayCategoryChildren(category, false);
}

async function displayCategoryTypeChildren(categoryTypeId: string) {
  currentType.value = Type.getCategoryType(categoryTypeId);
  selectedCategoryPath.value = [];
  showCategoryTypes.value = false;
  categoriesLoading.value = true;
  categories.value = await loadRootCategories(categoryTypeId);
  categoriesLoading.value = false;
}

async function onBackNew(ids: string[]) {
  selectedCategoryPath.value.pop();
  getSelectedCategory()
  await displayCategoryChildren(getSelectedCategory(), false);
}

async function routerNext(id: string) {
  const ids = getPathIdsAsArray();
  if (ids.length > 0) {
    await router.push(`${ids[ids.length - 1]}/${id}`);
  } else {
    await router.push({name: routeNames.CATEGORIES_CHILD, params: {ids: `${id}`}});
  }
}

async function onAddCategory() {
  const currentSelected = getSelectedCategory();
  const parentCategoryId = currentSelected?.id ?? '';
  const categoryTypeId = currentType.value?.id
  await router.push({name: routeNames.CREATE_CATEGORY, query: {parentCategoryId: parentCategoryId, categoryTypeId: categoryTypeId}})
}

async function onEditCategory(category: Category) {
  await router.push({name: routeNames.EDIT_CATEGORY, params: {id: category.id}})
}

function displayRoot() {
  selectedCategoryPath.value = [];
  currentType.value = null;
  showCategoryTypes.value = true;
}

function getSelectedCategory(): Category {
  return selectedCategoryPath.value[selectedCategoryPath.value.length - 1];
}

async function displayCategoryChildren(category: Category, addToPath: boolean = true) {
  showCategoryTypes.value = false;

  if (addToPath) selectedCategoryPath.value.push(category);

  categoriesLoading.value = true;
  categories.value = await loadCategoryChildren(category.id);
  categoriesLoading.value = false;
}

function getPathIdsAsArray(): string[] {
  return !route.params.ids ? [] : (
    typeof route.params.ids == 'string'
      ? [route.params.ids]
      : route.params.ids as string[]);
}

</script>

<style scoped>

.dynamic-style {
  border-color: var(--dynamic-border-color);
}

.dynamic-style:hover {
  background-color: var(--dynamic-border-color);
}

</style>