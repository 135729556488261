<template>
  <base-card class="transition-all ease-in-out flex justify-between items-center group hover:border-primary cursor-pointer container" @click="onClickToProject">
    <div class="flex flex-col w-full">
      <div class="flex items-center space-x-[4px] min-w-0 pb-2">
        <div class="text-bodySmallNormal whitespace-nowrap overflow-hidden text-ellipsis flex-1 min-w-0">
          {{ TenantSettings.isCustomerOriented() ? Utils.getCustomerOneLineAddress(project.customer) : Utils.getCustomerTitle(project.customer) }}
        </div>
        <p class="text-bodySmallBold flex-shrink-0 ml-2" :style="{ color: project.statusId ? Type.getStatus(project.statusId).displayColor : '#ffffff' }">
          {{ project.statusId ? Type.getStatus(project.statusId).name : '' }}
        </p>
      </div>
      <p class="text-bodyMediumBold group-hover:text-primary whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
        {{ project.name }}
      </p>
    </div>
  </base-card>
</template>


<style scoped>
div:has(button:hover) {
  @apply border-grey-mid shadow-none;
}

div:has(button:hover) > div > p {
  @apply !text-black;
}
</style>

<script setup lang="ts">
import type Project from "@/model/Project";
import { Type } from '../../model/Type'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router'
import { Utils } from '../../client/utils'
import { TenantSettings } from '../../stores/TenantSettings'

const router = useRouter();

const props = defineProps<{
  project: Project;
}>();

async function onClickToProject() {
  await router.push({name: routeNames.PROJECT, params: { id: props.project.id }});
}

</script>
