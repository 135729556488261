import Keycloak from 'keycloak-js'
import type { KeycloakProfile } from 'keycloak-js'
import { ResponseKey } from '@/model/ResponseWrapper'
import type ResponseWrapper from '@/model/ResponseWrapper'
import { API } from '@/client/axios'
import type { AxiosInstance } from 'axios'
import axios from 'axios'
import { Utils } from '@/client/utils'

export class KeycloakService {

  private static readonly _keycloakUrl = window.KEYCLOAK_URL != '${KEYCLOAK_URL}'
    ? window.KEYCLOAK_URL : 'http://localhost:9090';
  private static readonly _realm = window.KEYCLOAK_REALM != '${KEYCLOAK_REALM}'
    ? window.KEYCLOAK_REALM : 'dev';
  private static readonly _clientId = window.KEYCLOAK_CLIENT != '${KEYCLOAK_CLIENT}'
    ? window.KEYCLOAK_CLIENT : 'redocks-rest-api';

  private static _keycloak: Keycloak | undefined;
  private static _profile: KeycloakProfile | undefined;

  private static _api: AxiosInstance | undefined;

  static get realm() {
    return this._realm;
  }

  static get keycloak() {
    if (!this._keycloak) {
      this._keycloak = new Keycloak({
        url: this._keycloakUrl,
        realm: this._realm,
        clientId: this._clientId
      });
    }
    return this._keycloak;
  }

  static get profile() {
    return this._profile;
  }

  static get url() {
    return this._keycloakUrl;
  }

  static get clientId() {
    return this._clientId;
  }

  static async init() {
    const authenticated = await this.keycloak.init({
      onLoad: 'login-required'
    });

    if (authenticated) {
      try {
        this._profile = await this.keycloak.loadUserProfile();
      } catch (e) {
        console.error(`Keycloak user profile could not be loaded. Error: ${Utils.getError(e)}`);
      }
    }

    return authenticated;
  }

  static async getToken() {
    if (this.keycloak.isTokenExpired()) {
      await this.keycloak.updateToken();
    }
    return this.keycloak.token;
  }

  static getUserName() {
    if (!this.profile) return '';
    return `${this._profile?.firstName} ${this._profile?.lastName}`;
  }

  static async login() {
    await this.keycloak.login()
  }

  static async logout() {
    await this.keycloak.logout();
  }

  static hasRole(role: Role) {
    return this.keycloak.hasResourceRole(role, this._clientId);
  }

  static isAdmin() {
    return this.hasRole(Role.ADMIN);
  }

  static isManager() {
    return this.hasRole(Role.MANAGER);
  }

  static isReporter() {
    return this.hasRole(Role.REPORTER);
  }

  private static async requestRealmFromUser() {
    let realm: string | null = '';
    let message = 'Mandantenschlüssel eingeben';
    let exists = RealmExistenceState.UNKNOWN
    do {
      realm = '';
      while (!realm || realm == '') realm = window.prompt(message, '');
      message = 'Mandantenschlüssel eingeben';

      exists = await this.realmExists(realm);
      switch (await this.realmExists(realm)) {
        case RealmExistenceState.REQUEST_ERROR: message = 'Anfrage war fehlerhaft. Bitte erneut versuchen\n' + message; break;
        case RealmExistenceState.UNKNOWN: message = `Mandant ${realm} existiert nicht\n` + message; break;
        default: break;
      }
    } while (exists != RealmExistenceState.EXISTS);
    return realm;
  }

  private static async realmExists(realm: string) {
    const response = (await this.api.get<ResponseWrapper<boolean>>(`tenants/${realm}/exists`));
    if (!response) return RealmExistenceState.REQUEST_ERROR;

    const wrapper = response.data;
    if (wrapper.key == ResponseKey.OK) {
      const exists = wrapper.data;
      if (exists) return RealmExistenceState.EXISTS;
      else return RealmExistenceState.UNKNOWN
    } else return RealmExistenceState.REQUEST_ERROR;
  }

  private static get api() {
    if (!this._api) {
      this._api = axios.create({
        baseURL: API.apiBaseUrl
      });
      this._api.interceptors.response.use(null, (error) => {
        return Promise.reject();
      });
    }
    return this._api;
  }
}

export enum Role {
  ADMIN = 'api_admin',
  MANAGER = 'api_manager',
  REPORTER = 'api_reporter'
}

enum RealmExistenceState {
  EXISTS,
  UNKNOWN,
  REQUEST_ERROR
}