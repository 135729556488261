<template>
  <h1 v-if="props.type === 'h1'" class="flex items-center text-title xs:font-bold mb-outerFrameBorder" :class="$attrs.class">
    <slot></slot>
  </h1>
  <h2 v-if="props.type === 'h2'" class="flex items-center text-bodyMediumBold mb-betweenElements" :class="$attrs.class">
    <slot></slot>
  </h2>
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    required: false,
    default: 'h1'
  }
})
</script>
