export class FormValidator {

  static validate(values: {value: string | number | undefined, validation: Validation, pattern?: RegExp}[]): boolean {
    for (const value of values) {
      if (!this.validateValue(value.value, value.validation, value.pattern)) return false;
    }
    return true;
  }

  static validateValue(value: string | number | undefined, validation: Validation, pattern?: RegExp): boolean {
    if (!pattern) {
      return this.present(value);
    }
    switch (validation) {
      case Validation.REQUIRED:
        return this.present(value)
      case Validation.PATTERN:
        return this.pattern(value, pattern)
      case Validation.REQUIRED_PATTERN:
        return this.present(value) && this.pattern(value, pattern);
    }
  }

  static present(value: string | number | undefined) {
    if (!value) return false;
    if (typeof value === 'string') return value.length > 0;
    return true;
  }

  static pattern(value: string | number | undefined, pattern: RegExp) {
    if (!value) return true;
    return pattern.test(value as string);
  }

}

export enum Validation {
  REQUIRED,
  PATTERN,
  REQUIRED_PATTERN
}

export class Pattern {
  static readonly ZIP = /^\d{4,5}$/;
  static readonly UID = /^(CHE-)?\d{3}\.\d{3}\.\d{3}$/;
  static readonly PHONE = /^(0\d{2}\s\d{3}\s\d{2}\s\d{2})|(\+\d{2}\s\d{2}\s\d{3}\s\d{2}\s\d{2})$/;
  static readonly EMAIL = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/;
  static readonly COUNTRY_CODE = /^$|^[A-Z]{2}$/;
  static readonly IBAN = /^CH\d{2}\s\d{4}\s\d{4}\s\d{4}\s\d{4}\s\d{1}$/;
}