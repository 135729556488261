<template>
  <base-container class="mb-betweenElements !bg-blue-mid">

    <spinner v-if="loading" />

    <div v-else class="flex flex-row">
      <div class="flex flex-col mr-auto">
        <div class="flex flex-row items-center">
          <p class="text-bodyMediumBold mb-2" :style="{ color: offer ? offerStatusColor(offer.status) : '#000000' }">
            {{ offer ? translateOfferStatus(offer.status) : '' }}
          </p>
          <base-icon v-if="offer ? offerStatusIsLocking(offer.status) : false"
                     class="ml-4 mb-2" size="text-iconSizeSmall" :style="{ color: offer ? offerStatusColor(offer.status) : '#000000' }">lock</base-icon>
        </div>
        <p class="text-bodyMediumBold">
          {{ offer ? Utils.formatCurrency(offer.costs) : 0 }} {{ TenantSettings.getCurrency() }}
        </p>
        <template v-if="offer">
          <p v-for="fieldValue of offer.customFieldValues" :key="fieldValue.id">
            {{ TenantFields.get(fieldValue.customFieldId)?.name }}: {{ fieldValue.value }}
          </p>
        </template>
      </div>
      <base-button v-if="KeycloakService.isManager() && offer && offer.linked" class="mr-betweenElements mt-auto mb-auto rounded-lg bg-blue-mid" type="icon" look="secondary" @click="onSync()">
        <phosphor-icon icon="arrows-clockwise" />
      </base-button>
      <base-button v-if="KeycloakService.isManager() && copyingAllowed" class="mr-betweenElements mt-auto mb-auto rounded-lg bg-blue-mid" type="icon" look="secondary" @click="onCopy()">
        <phosphor-icon icon="copy" />
      </base-button>
      <base-button v-if="KeycloakService.isManager() && offer && !offer.linked" class="mr-betweenElements mt-auto mb-auto rounded-lg bg-blue-mid" type="icon" look="secondary" @click="onAddSubproject()">
        <phosphor-icon icon="clipboard-text" />
      </base-button>
      <base-button v-if="KeycloakService.isManager()" class="mt-auto mb-auto rounded-lg bg-blue-mid" type="icon" look="secondary" @click="onEditOffer()">
        <phosphor-icon icon="pencil-simple" />
      </base-button>
    </div>
  </base-container>
</template>

<script setup lang="ts">

import { offerStatusColor, offerStatusIsLocking, translateOfferStatus } from '@/model/Type'
import { KeycloakService } from '@/service/keycloakService'
import { Utils } from '@/client/utils'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import type { Offer } from '@/model/Offer'
import { API, PathSegment } from '@/client/axios'
import { onMounted, ref, watch } from 'vue'
import router, { routeNames } from '@/router'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { TenantSettings } from '../../stores/TenantSettings'
import { TenantFields } from '@/stores/TenantFields'
import Spinner from '@/components/generel/Spinner.vue'
import { UrlData } from '@/client/UrlData'

const props = defineProps<{
  offerId: string;
  copyingAllowed: boolean;
}>();

const emits = defineEmits(['on-copy', 'on-sync']);

const loading = ref(true);
const offer = ref<Offer>();

function onEditOffer() {
  router.push({name: routeNames.EDIT_OFFER, params: {id: props.offerId}});
}

async function loadOffer(id: string) {
  loading.value = true;
  const response = await API.getDataObject<Offer>(PathSegment.OFFERS, id, 'Offerte');
  if (!response) return;
  offer.value = response;
  loading.value = false;
}

function onAddSubproject() {
  router.push({
    name: routeNames.CREATE_SUBPROJECT, 
    query: {
      projectId: offer.value?.projectId, 
      offerId: props.offerId, 
      name: offer.value?.name, 
      costLimit: offer.value?.costs,
      chapters: UrlData.toUrlData(offer.value?.chapters)
    }});
}

function onCopy() {
  emits('on-copy');
}

function onSync() {
  emits('on-sync', offer.value?.costs);
}

watch(() => props.offerId,  newVal => {
  if (!newVal || !offer.value) return;
  loadOffer(newVal);
}, {
  immediate: true
});

onMounted(() => {
  loadOffer(props.offerId);
})

</script>

<style scoped>

</style>