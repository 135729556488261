<template>

  <base-container>
    <div class="flex justify-between mb-5">
      <base-heading>
        <div class="title-icon-with-background">
          <phosphor-icon icon="percent" />
        </div>
        Rabatte & Aufschläge
      </base-heading>
      <base-button v-if="!locked" class="mb-auto rounded-lg" type="icon" look="secondary" @click="onAddDiscount">
        <phosphor-icon icon="plus" />
      </base-button>
    </div>

    <spinner v-if="loading" />

    <div v-else>
      <div v-if="discounts.length == 0">
        <p class="text-bodyMediumBold">Keine Einträge vorhanden</p>
      </div>

      <div v-else>

        <div class="grid gap-4">
          <!-- Header -->
          <div class="bg-grey-mid p-4 md:rounded-md rounded border border-grey-mid grid grid-cols-3 gap-4">
            <div class="col-span-2 overflow-hidden">Name</div>
            <div class="col-span-1 overflow-hidden text-right">Menge</div>
          </div>
          <!-- Content -->
          <div v-for="discount in discounts" :key="discount.id" class="grid grid-cols-3 gap-4" @click="onEditDiscount(discount.id)" :class="{'selectable-md': !locked, 'unselectable-md': locked}">
            <div class="col-span-2 overflow-hidden">{{Type.getDiscountType(discount.discountTypeId).name}}</div>
            <div class="col-span-1 overflow-hidden text-right">{{Type.getDiscountType(discount.discountTypeId).deduction ? '-' : '+'}} {{Type.getDiscountType(discount.discountTypeId).relative ? discount.amount : Utils.formatCurrency(discount.amount)}} {{Type.getDiscountType(discount.discountTypeId).relative ? '%' : TenantSettings.getCurrency()}}</div>
          </div>
        </div>

      </div>

    </div>
  </base-container>

</template>

<script setup lang="ts">
import router, { routeNames } from '@/router'
import type Discount from '@/model/Discount'
import { Type } from '../../model/Type'
import Spinner from '@/components/generel/Spinner.vue'
import { Utils } from '../../client/utils'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { TenantSettings } from '../../stores/TenantSettings'
import { API, PathSegment } from '@/client/axios'
import { onMounted, ref, watch } from 'vue'

const props = defineProps<{
  invoiceId: string;
  locked: boolean;
}>();

const loading = ref(false);
const discounts = ref<Discount[]>([]);

async function onAddDiscount() {
  await router.push({name: routeNames.CREATE_DISCOUNT, query: {subprojectId: props.invoiceId}})
}

async function onEditDiscount(id: string) {
  if (props.locked) return;
  await router.push({name: routeNames.EDIT_DISCOUNT, params: {id: id}, query: {subprojectId: props.invoiceId}})
}

watch(() => props.invoiceId, async newVal => {
  if (!newVal) return;
  await loadDiscounts()
}, {
  immediate: true
});

async function loadDiscounts() {
  loading.value = true;
  const response = await API.wrapGet<Discount[]>(API.get(PathSegment.INVOICES, props.invoiceId, PathSegment.DISCOUNTS), 'Rabatte & Aufschläge');
  if (!response) return;
  discounts.value = response;
  loading.value = false;
}

</script>

<style scoped>

</style>