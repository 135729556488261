<template>

  <base-site :title="titleText" :show-button="!!invoiceId" button-icon="trash" button-look="secondary-light" @button-action="openModal">
    <base-container class="mb-betweenElements">
      <spinner v-if="loading" />
      <div v-else>
        <FormKit type="text" name="name" id="name" label="Name des Rapports*" validation="required" v-model="subprojectName" />
        <FormKit type="number" number inputmode="decimal" step="any" name="costLimit" id="costLimit" label="Kostendach" :min="0" v-model="subprojectCostLimit" />

        <custom-fields ref="customFields"
                       :entity="CustomFieldEntity.SUBPROJECT"
                       :entity-id="invoice?.id ?? ''"
                       :custom-field-values="invoice?.customFieldValues" />

        <base-heading type="h2" class="pb-1 mt-5 !mb-0">Status*</base-heading>
        <base-container class="md:mb-0 mb-betweenElements !rounded-sm">
          <div class="flex flex-wrap justify-between">
            <button v-for="status of Type.getAllStatusesOrderByPriority()" :key="status.id"
                    :class="`flex-grow border-2 rounded-md px-10 text-bodyMediumBold py-7 sm:py-6 mr-3 last:mr-0 mb-3`"
                    :style="`color: ${status.displayColor}; border-color: ${subprojectStatusId == status.id ? status.displayColor : '#f5f5ff'}`" @click="onChangeStatus(status.id)">
              {{status.name}}
            </button>
          </div>
        </base-container>

        <dropdown label="Skonto & Mehrwertsteuer">
          <div class="flex flex-row flex-wrap mt-3 overflow-hidden">
            <base-container class="md:mr-betweenElements flex-grow md:mb-0 mb-betweenElements !rounded-sm" type="inner">
              <FormKit type="checkbox" label="Skonto" v-model="skontoCheckbox" />
              <div class="flex md:flex-row flex-col">
                <div class="md:mr-betweenElements"><FormKit type="number" number inputmode="decimal" step="any" label="Skonto in %" :disabled="!skontoCheckbox" v-model="skonto"/></div>
                <FormKit type="date" name="date" id="date" label="Skonto Ablaufdatum" :disabled="!skontoCheckbox" v-model="skontoExpiration" />
              </div>
            </base-container>
            <base-container class="flex-grow !rounded-sm" type="inner">
              <div class="text-bodyMediumBold mb-1">Mehrwertssteuer in Preise</div>
              <radio-select
                :options="[
                { label: 'Exklusive', value: VatType.EXCLUSIVE },
                { label: 'Inklusive', value: VatType.INCLUSIVE },
                { label: 'Steuerfrei', value: VatType.VAT_FREE }
              ]"
                :init-option="vatOption"
                @on-select="(value: VatType) => vatOption = value"
              />
            </base-container>
          </div>
        </dropdown>

        <dropdown v-if="!TenantSettings.isTransport()" label="Phasen">
          <div v-if="chapters.length == 0" class="text-grey-dark text-bodyMediumBold p-5">Keine Phase vorhanden</div>
          <div v-for="chapter of chapters" :key="chapter.index" class="flex flex-row p-5 border border-grey-mid rounded mt-5 items-center">
            <span>{{ chapter.index + 1 }}. {{ chapter.name }}</span>
            <div class="ml-auto w-15">
              <button class="hover:text-primary transition-colors pl-10" @click="chapterMenuVisible[chapter.index] = true">
                <phosphor-icon icon="dots-three-vertical" />
              </button>
              <chapter-menu v-if="chapterMenuVisible[chapter.index]"
                            @on-click-outside="chapterMenuVisible[chapter.index] = false"
                            @on-edit="onEditChapter(chapter.index)"
                            @on-move-up="onMoveChapterUp(chapter.index)"
                            @on-move-down="onMoveChapterDown(chapter.index)"
                            @on-delete="onDeleteChapter(chapter.index)" />
            </div>
          </div>
          <base-button look="secondary" class="mt-5 w-full rounded-sm p-5" @click="openChapterNameModal">
            <phosphor-icon icon="plus" />
          </base-button>
        </dropdown>
      </div>

      <confirmation-modal ref="confirmDeletionModal"
                          title="Rapport löschen"
                          confirm-text="Löschen"
                          @on-confirm="onDelete">
        Soll der Rapport wirklich gelöscht werden?</confirmation-modal>

      <confirmation-modal ref="chapterNameModal"
                          :title="chapterEditIndex == -1 ? 'Phase hinzufügen' : 'Phase bearbeiten'"
                          :confirm-text="chapterEditIndex == -1 ? 'Hinzufügen' : 'Speichern'"
                          @on-confirm="() => chapterEditIndex == -1 ? onAddChapter() : editChapter()"
                          @close-action="chapterName = ''; chapterEditIndex = -1">
        <FormKit type="text" label="Name*" validation="required" v-model="chapterName" />
      </confirmation-modal>
    </base-container>

    <div v-if="!loading" class="flex md:flex-row flex-col">
      <base-button look="primary" :disabled="!validate()" class="md:mr-auto md:mb-0 md:mx-0 mx-betweenElements md:w-buttonXLarge" @click="onSubmit">
        <spinner-small v-if="requesting" />
        <div v-else>{{ submitText }}</div>
      </base-button>
      <base-button look="secondary-light" class="md:mx-0 md:mt-0 mt-betweenElements mx-betweenElements md:w-buttonXLarge" @click="onCancel">Abbrechen</base-button>
    </div>
  </base-site>
</template>

<script setup lang="ts">
import { API, PathSegment } from '@/client/axios'
import { type Invoice, type InvoiceRequest, VatType } from '@/model/Invoice'
import { Type } from '@/model/Type'
import { useRoute } from 'vue-router'
import router, { routeNames } from '@/router'
import { onMounted, ref } from 'vue'
import BaseSite from '@/components/base/BaseSite.vue'
import ConfirmationModal from '@/components/generel/ConfirmationModal.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import SpinnerSmall from '@/components/generel/SpinnerSmall.vue'
import { DateConverter } from '@/client/DateConverter'
import CustomFields from '@/components/generel/CustomFields.vue'
import { CustomFieldEntity, type CustomFieldValue } from '@/model/CustomField'
import Spinner from '@/components/generel/Spinner.vue'
import RadioSelect from '@/components/generel/RadioSelect.vue'
import BaseContainer from '@/components/base/BaseContainer.vue'
import BaseHeading from '@/components/base/BaseHeading.vue'
import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { TenantSettings } from '@/stores/TenantSettings'
import { FormValidator, Validation } from '@/client/FormValidator'
import Dropdown from '@/components/generel/Dropdown.vue'
import type { ChapterRequest } from '@/model/Chapter'
import ChapterMenu from '@/components/invoice/ChapterMenu.vue'
import { useToast } from 'vue-toast-notification'
import { UrlData } from '@/client/UrlData'

const SUBPROJECT = 'Rapport';
const route = useRoute();

const loading = ref(false);
const requesting = ref(false);
const invoiceId = route.params.id as string;
const invoice = ref<Invoice>();
const projectId = route.query.projectId as string;
const offerId = route.query.offerId as string;
const costLimit = +(route.query.costLimit as string);
const name = route.query.name as string;
const titleText = ref('Rapport');
const submitText = ref<string>();

const subprojectName = ref<string>(name);
const subprojectCostLimit = ref(costLimit ?? 0);
const skontoCheckbox = ref(false);
const skonto = ref(2);
const skontoExpiration = ref('');
const subprojectStatusId = ref<string>(Type.getAllStatusesOrderByPriority()[0].id);

const chapterName = ref('');
const chapterEditIndex = ref(-1);
const chapters = ref<ChapterRequest[]>(route.query.chapters ? UrlData.toData<ChapterRequest[]>(route.query.chapters as string) : []);
const chapterNameModal = ref(ConfirmationModal);
const chapterMenuVisible = ref<boolean[]>([]);

const customFields = ref<InstanceType<typeof CustomFields> | null>(null);
const confirmDeletionModal = ref(ConfirmationModal);

const vatOption = ref(TenantSettings.get.pricesIncludingVat ? VatType.INCLUSIVE : VatType.EXCLUSIVE);

const $toast = useToast();

async function onSubmit() {
  if (!validate()) return;
  if (!projectId && !invoice.value) {
    console.error('Invoice or project id is not set!');
    return;
  }

  const fields: any = {
    name: subprojectName.value,
    costLimit: subprojectCostLimit.value,
    statusId: subprojectStatusId.value,
    skonto: skontoCheckbox.value ? skonto.value : 0,
    skontoExpiration: skontoCheckbox.value ? DateConverter.convertToUnixTimestamp(skontoExpiration.value) : 0,
    customFields: getCustomFieldValues()
  }
  if (projectId) await onCreate();
  else await onUpdate();
}

async function onCreate() {
  if (requesting.value) return;
  const subprojectRequet: InvoiceRequest = {
    projectId: projectId,
    name: subprojectName.value,
    costLimit: subprojectCostLimit.value,
    statusId: subprojectStatusId.value,
    skonto: skontoCheckbox.value ? skonto.value : 0,
    skontoExpiration: skontoCheckbox.value ? DateConverter.convertToUnixTimestamp(skontoExpiration.value) : 0,
    chapters: [...chapters.value.map(c => {return {name: c.name, index: c.index}})],
    customFieldValues: getCustomFieldValues(),
    offerId: offerId,
    vatType: vatOption.value
  }
  requesting.value = true
  const response = await API.createDataObject<Invoice, InvoiceRequest>(
    PathSegment.INVOICES,
    subprojectRequet,
    SUBPROJECT
  );
  if (response) router.push({ name: routeNames.PROJECT, params: { id: response.projectId } })
  requesting.value = false
}

async function onUpdate() {
  if (requesting.value) return;
  const subprojectRequet: InvoiceRequest = {
    id: invoiceId,
    projectId: invoice.value?.projectId ?? '',
    name: subprojectName.value,
    costLimit: subprojectCostLimit.value,
    statusId: subprojectStatusId.value,
    skonto: skontoCheckbox.value ? skonto.value : 0,
    skontoExpiration: skontoCheckbox.value ? DateConverter.convertToUnixTimestamp(skontoExpiration.value) : 0,
    chapters: chapters.value,
    customFieldValues: getCustomFieldValues(),
    offerId: offerId,
    vatType: vatOption.value,
  }
  requesting.value = true;
  const response = await API.updateDataObject<Invoice, InvoiceRequest>(PathSegment.INVOICES, subprojectRequet, SUBPROJECT);
  if (response) router.go(-1);
  requesting.value = false;
}

async function onDelete() {
  const success = await API.deleteDataObject(PathSegment.INVOICES, invoiceId, SUBPROJECT);
  if (success) {
    router.go(-1);
  }
}

function onChangeStatus(id: string) {
  subprojectStatusId.value = id;
}

function onAddChapter() {
  if (!chapterName.value) {
    $toast.error('Kein Phasenname angegeben');
    return;
  }
  if (chapters.value.find(c => c.name === chapterName.value)) {
    $toast.error('Phasenname bereits vergeben');
    return;
  }
  chapters.value = [...chapters.value, {name: chapterName.value, index: chapters.value.length}];
  chapterName.value = '';
  chapterMenuVisible.value = [...chapters.value.map(() => false)];
}

function onEditChapter(index: number) {
  chapterMenuVisible.value[index] = false;
  chapterEditIndex.value = index;
  chapterName.value = chapters.value[index].name;
  openChapterNameModal();
}

function editChapter() {
  if (!chapterName.value) {
    $toast.error('Kein Phasenname angegeben');
    return;
  }
  if (chapters.value.find(c => c.name === chapterName.value)) {
    $toast.error('Phasenname bereits vergeben');
    return;
  }
  chapters.value[chapterEditIndex.value].name = chapterName.value;
  chapterName.value = '';
  chapterEditIndex.value = -1;
}

function onMoveChapterUp(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index <= 0 || index >= chapters.value.length) {
    console.warn("Cannot move the object up further.");
    return;
  }

  const temp = chapters.value[index];
  chapters.value[index] = chapters.value[index - 1];
  chapters.value[index - 1] = temp;

  chapters.value[index].index = index;
  chapters.value[index - 1].index = index - 1;
}

function onMoveChapterDown(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index < 0 || index >= chapters.value.length - 1) {
    console.warn("Cannot move the object down further.");
    return;
  }

  const temp = chapters.value[index];
  chapters.value[index] = chapters.value[index + 1];
  chapters.value[index + 1] = temp;

  chapters.value[index].index = index;
  chapters.value[index + 1].index = index + 1;
}

function onDeleteChapter(index: number) {
  chapterMenuVisible.value[index] = false;
  if (index < 0 || index >= chapters.value.length) {
    console.warn("Invalid index. Cannot remove the chapter.");
    return;
  }

  chapters.value.splice(index, 1);

  for (let i = index; i < chapters.value.length; i++) {
    chapters.value[i].index = i;
  }
}

function onCancel() {
  router.go(-1);
}

const openModal = () => {
  if (confirmDeletionModal.value) {
    confirmDeletionModal.value.openModal();
  }
};

function openChapterNameModal() {
  if (!chapterNameModal.value) return;
  chapterNameModal.value.openModal();
}

async function loadSubproject() {
  loading.value = true;
  const response = await API.getDataObject<Invoice>(PathSegment.INVOICES, invoiceId);
  if (!response) return;
  invoice.value = response;
  subprojectName.value = response.name;
  subprojectCostLimit.value = response.costLimit;
  subprojectStatusId.value = response.statusId;
  skontoCheckbox.value = response.skonto ? response.skonto != 0 : false;
  skonto.value = response.skonto ?? 0;
  skontoExpiration.value = response.skontoExpiration ? DateConverter.convertToLocalDateISO(response.skontoExpiration) : '';
  vatOption.value = response.vatType;
  chapters.value = response.chapters;
  chapterMenuVisible.value = response.chapters.map(() => false);
  loading.value = false;
}

function getCustomFieldValues(): CustomFieldValue[] | undefined {
  if (customFields.value) return customFields.value.getValues();
}

function validate() {
  return FormValidator.validate([
    {value: subprojectName.value, validation: Validation.REQUIRED},
  ])
}

onMounted(async () => {
  if (!invoiceId && !projectId) {
    console.error('Invoice or project id is not set!');
    return;
  }

  if (invoiceId) {
    titleText.value = 'Rapport brearbeiten';
    submitText.value = 'Rapport speichern';
    await loadSubproject();
  } else {
    titleText.value = 'Rapport erstellen';
    submitText.value = 'Rapport erfassen';
  }
});

</script>

<style scoped>

</style>
