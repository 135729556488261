<template>

  <div :class="`flex ${orientation === 'vertical' ? 'flex-col' : 'flex-row'} justify-between w-full`">
    <div v-for="option of options" :key="option.value"
            :class="`${selected == option.value ? 'selected' : 'selectable'} text-left flex flex-row flex-grow ${orientation === 'vertical' ? 'mb-4 last:mb-0' : 'mr-4 last:mr-0'} py-5 md:py-3`"
            @click="onSelect(option.value)">
      <div :class="`${selected == option.value ? 'w-8' : 'ml-8'}`">
        <phosphor-icon v-if="selected == option.value" icon="check" size="text-iconSizeSmall" />
      </div>
      <span>{{ option.label }}</span>
    </div>
  </div>

</template>

<script setup lang="ts">

import PhosphorIcon from '@/components/base/PhosphorIcon.vue'
import { onMounted, ref } from 'vue'

const props = withDefaults(defineProps<{
  options: {label: string, value: any}[];
  initOption: any;
  orientation?: string;
}>(), {
  orientation: 'vertical'
});

const emits = defineEmits(['on-select']);

const selected = ref<any>();

function onSelect(value: any) {
  selected.value = value;
  emits('on-select', value);
}

function setSelected(value: any) {
  selected.value = value;
}

onMounted(() => {
  selected.value = props.initOption;
})

defineExpose({
  setSelected
})

</script>