
export interface Chapter {
  id: string,
  name: string,
  index: number
}

export interface ChapterRequest {
  id?: string,
  name: string,
  index: number
}

export enum ChapterDefault {
  ALL = "all",
  NONE = "none"
}