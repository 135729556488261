import type Company from '@/model/Company'
import { ObjectStorage, StorageKey } from '@/service/ObjectStorage'

type CompanyEmitter = (company: Company) => undefined;
export class TenantCompany {

  private static company: Company;
  private static companyEmitters: CompanyEmitter[] = [];

  static getCompany() {
    if (!this.company) this.loadCompanyFromStore();
    return this.company;
  }

  static setCompany(company: Company) {
    this.setAndEmitCompany(company);
  }

  static loadCompanyFromStore() {
    const loaded = ObjectStorage.get<Company>(StorageKey.COMPANY);
    if (!loaded) return false;
    this.setAndEmitCompany(loaded);
    return true;
  }

  static register(companyEmitter: CompanyEmitter) {
    this.companyEmitters.push(companyEmitter);
    if (this.company) companyEmitter(this.company);
  }

  private static setAndEmitCompany(company: Company) {
    this.company = company;
    this.companyEmitters.forEach(emit => {
      emit(this.company);
    })
  }
}